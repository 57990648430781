/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import React, { useContext, useEffect, useState } from 'react';
import {
  Modal, Form, Switch, Input,
  Button,
  Spin,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { cloneDeep } from 'lodash';
import AutoComplete from './AutoComplete';
import usePortal from '../../hooks/usePortal';
import { AclWrapperContext } from '../../context/ACLWrapperContext';
import { FromStatisticsContext } from '../../context/FromStatisticsContext';
import { addToArray } from '../../functions/changeArray';

/**
 * Component to handle all the settings in a component regarding ACLing
 * It is a modal that gets triggered from outside, with the use of settingsVisible
 * and setACLSettings, we pass the item which will be an object of the settings from that component
 */

const ACLSettings = ({
  // Sets Hide o=or Show
  setACLSettings,
  // Will Show
  settingsVisible,
  // What is moddifying
  item,
  // Form that store the values of the Rules
  // form,
  // On Ok Handle
  onOk,
  // handleSubmit Handler
  // handleSubmit,
  // Who is the user seeing this
  // user,
  // Function to Clone the Rules
  // cloneRules,
  // Is an Enable Feature or Not
  enable,
  // What's the title of the Component
  title,
  getData = () => { },
  // Premade form
  // customForm,
}) => {
  const AclContext = useContext(AclWrapperContext);
  const {
    user, fields, cloneRules, handleSubmit, itemType,
  } = AclContext;
  const [form] = Form.useForm();
  const [cloneForm] = Form.useForm();
  const [officeFilters, setOfficeFilters] = useState([]);
  const [branchFilters, setBranchFilters] = useState([]);

  const [show, openModal, onOkClone, onCancel] = usePortal({
    onOkPromise: () => cloneForm.validateFields()
      .then((values) => {
        cloneRules(values);
      }),

  });

  useEffect(() => {
    if (fields) {
      // console.log('Fields', fields);
      form.setFields(fields);
    }
  }, [fields]);

  const handleOk = () => {
    if (onOk) {
      // console.log('Custom On Ok');
      // Custom ok event
      onOk(form.getFieldsValue());
      getData(form.getFieldsValue());
    } else {
      // Old behavhiour for onOk
      form.submit();
    }
  };
  const handleCancel = () => {
    getData(form.getFieldsValue());
    setACLSettings(false);
  };
  // console.log('Custom Form', !isEmpty(contextForm));
  // console.log('Console Custom Form')
  // const form = !isEmpty(contextForm) ? contextForm : customForm;
  // eslint-disable-next-line no-underscore-dangle

  useEffect(() => {
    if (user?.mmsc) {
      if (user.mmsc <= 5)setBranchFilters([user.office_agent_id]);
      if (user.main_office_id && user.mmsc <= 6) {
        setOfficeFilters([user.main_office_id]);
      }
    }
  }, [user]);

  const {
    fromStatistics,
    publicChartsHandlers,
    selectedViewItem,
  } = useContext(FromStatisticsContext);

  // console.log('FOR IN SETTINGS', form);
  return (
    <>
      <Modal
        forceRender
        title={`${title} Settings`}
        visible={settingsVisible}
        onCancel={handleCancel}
        onOk={() => {
          handleOk();
          if (fromStatistics) {
            const newSelectedViewItem = cloneDeep(selectedViewItem);
            newSelectedViewItem.acl_settings.only_me = !form.getFieldValue(['public']);
            publicChartsHandlers.setLocalCharts(addToArray(publicChartsHandlers.localCharts, newSelectedViewItem));
          }
          setACLSettings(false);
        }}
        keyboard={false}
        maskClosable={false}
        closable={false}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          initialValues={{
            public: true,
            name: title || '',
          }}
        >
          {!user && <Spin />}
          {
              user && (
              <>
                { !enable && item?.type !== 'Dashboard-Tab' ? (
                  <Form.Item
                    name="name"
                    label="Title"
                    hidden
                  />
                ) : (null)}

                {!enable ? (
                  <Form.Item
                    name="public"
                    label={(
                      <span>
                        Make Public
                        <QuestionCircleOutlined style={{ marginLeft: 3 }} />
                      </span>
        )}
                    valuePropName="checked"
                  >
                    <Switch
                      id={`public-${title}`}
                      size="small"
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                    />
                  </Form.Item>
                ) : null}

                {user?.mmsc >= 3 ? (
                  <Form.Item
                    id={`members-${title}`}

                    name="members"
                    label="Allowed Members"
                  >
                    <AutoComplete
                      column={{
                        field: 'User',
                        defaultValue: 'All',
                        key: 'agent_id',
                        displayValue: 'full_name',
                      }}
                      filter={{
                        values: branchFilters,
                        field: 'office_agent_id',
                      }}
                      allowNone
                      acl
                      multipleOptions
                    />
                    {/* <Select
            disabled
            mode="multiple"
            showSearch
            style={{ width: '100%' }}
            placeholder="Allowed Members"
            optionFilterProp="children"
          /> */}
                  </Form.Item>
            ) : (null) }

                {user?.mmsc >= 5 ? (
                  <Form.Item
                    name="offices"
                    label="Allowed Branches"
                    id={`offices-${title}`}

                  >
                    <AutoComplete
                      column={{
                        field: 'Branch',
                        defaultValue: 'All',
                        key: 'office_id',
                        displayValue: 'office_name',
                      }}
                      allowNone
                      acl
                      multipleOptions
                      onChange={(values) => {
                        // Generate the Filters, and avoid the All Value as a Filter
                        const fixedValues = values.map((val) => parseInt(val, 10)).filter(Boolean);
                        const filters = [];
                        if (user.main_office_id && user.mmsc <= 5) {
                          filters.push(user.main_office_id);
                        }
                        filters.push(...fixedValues);
                        setBranchFilters(filters);
                      }}
                      filter={{
                        values: officeFilters,
                        field: 'main_office_id',
                      }}
                    />
                  </Form.Item>
            ) : null}
                {user?.mmsc >= 6 ? (
                  <Form.Item
                    name="firms"
                    label="Allowed Offices"
                    id={`firms-${title}`}

                  >
                    <AutoComplete
                      column={{
                        field: 'Office',
                        defaultValue: 'All',
                        key: 'office_id',
                        displayValue: 'office_name',
                      }}
                      allowNone
                      acl
                      multipleOptions
                      onChange={(values) => {
                        // Generate the Filters, and avoid the All Value as a Filter

                        const fixedValues = values.map((val) => parseInt(val, 10)).filter(Boolean);
                        const filters = [];
                        if (user.main_office_id && user.mmsc <= 6) {
                          filters.push(user.main_office_id);
                        }
                        filters.push(...fixedValues);
                        setOfficeFilters(filters);
                      }}
                  // We only want it to be used if the user has a mmsc lower than 6
                      filter={user.mmsc <= 6 ? {
                        values: officeFilters,
                        field: 'main_office_id',
                      } : {}}
                    />
                  </Form.Item>
            ) : null}
                {user?.mmsc >= 8 ? (
                  <Form.Item
                    name="level"
                    label="Allowed User Levels"
                    id={`levels-${title}`}

                  >
                    <AutoComplete
                      column={{
                        field: 'Levels',
                        defaultValue: 'All',
                        key: 'level_id',
                        displayValue: 'level',
                      }}
                      allowNone
                      acl
                      multipleOptions
                  // Generate Level Values
                      localAutoComplete={Array(user.mmsc + 1).fill(1).map((val, index) => ({
                        level_id: index,
                        level: index,
                      }))}
                    />
                  </Form.Item>
            ) : null}
                {user?.mmsc >= 9 ? (
                  <Form.Item
                    name="originating_system_id"
                    label="Allowed MLS Accounts"
                    id={`mls-${title}`}

                  >
                    <AutoComplete
                      column={{
                        field: 'MLS',
                        defaultValue: 'All',
                        key: 'mls_id',
                        displayValue: 'mls_name',
                      }}
                      allowNone
                      acl
                      multipleOptions
                    />
                  </Form.Item>
            ) : null}
                {!enable && itemType === 'ViewItem' && (
                  <Form.Item

                    name="clone"
                    label="Clone"
                  >
                    <Button onClick={openModal} type="dashed" color="white">Clone</Button>
                  </Form.Item>
                )}
              </>
              )
            }
        </Form>
      </Modal>
      <Modal
        title="Clone Element"
        visible={show}
        onOk={onOkClone}
        onCancel={onCancel}
        keyboard={false}
        maskClosable={false}
        closable={false}
        forceRender
      >
        <Form
          form={cloneForm}
        >
          <Form.Item
            name="name"
            label="Title"
            rules={[

              // eslint-disable-next-line no-unused-vars
              ({ getFieldValue }) => ({
                validator(rules, value) {
                  if (!value || value === form.getFieldValue('name')) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject('The new title must be different from the original title');
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>

        </Form>
      </Modal>
    </>
  );
};

export default ACLSettings;
