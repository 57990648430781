import React, { useState, useEffect } from 'react';
// eslint-disable-next-line no-unused-vars
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { camelCase, isEmpty, last } from 'lodash';
import { OAUTH } from '../../constants';

const { Option } = Select;

const CMS_API = 'https://oauth2.mlsdatatools.com/api/';
const getUrl = window.location;
let CUBEJS_API = '';
if (process.env.NODE_ENV === 'development') {
  // CUBEJS_URL = 'http://localhost:3800/cubejs-api/v1';
  CUBEJS_API = 'https://bridgemls.mlsdatatools.com/api/lookups/';
} else {
  CUBEJS_API = `${getUrl.protocol}//${getUrl.host}/api/lookups/`;
}
// const CUBEJS_API = 'https://dev-react.mlsdatatools.com/api/lookups/';
// const CMS_API = 'http://localhost:8000/api/';
// const CUBEJS_API = 'http://localhost:3800/api/lookups/';

const getInitialState = (value, column, multipleOptions, multiple) => {
  if (value) {
    return multipleOptions ? [value] : value;
  }
  if (multiple) {
    return column.map((currentColumn) => (multipleOptions ? [currentColumn.defaultValue] : currentColumn.defaultValue));
  }
  return multipleOptions ? [column.defaultValue] : column.defaultValue;
};

// Value comes from a Form
const AutoComplete = ({
  column,
  multiple,
  filter,
  onChange,
  onSelect,
  onDeselect,
  cubejs,
  acl,
  cms,
  etl,
  subscriptions,
  multipleOptions,
  value,
  localAutoComplete,
  allowNone,
  afterSelect = 'none',
  noCamelCase,
}) => {
  // Saves the Different Selected values
  // If the Value from a Form exists, use that value, otherwise use the defaults
  const [selectedValue, setSelectedValue] = useState(
    getInitialState(value, column, multipleOptions, multiple),
  );

  // Stores Look up data
  const [lookupData, setLookupData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [maxPages, setMaxPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  // Lodas the Look up Data from the Server
  const loadData = async () => {
    const baseURL = cubejs ? CUBEJS_API : CMS_API;
    let route = '';
    const limit = 100;
    let paginationQuery = '';
    if (acl) {
      route = 'acl/';
      paginationQuery = `?limit=100&$offset=${currentPage * limit}`;
    }
    if (cms) route = 'cms/';
    if (etl) route = 'etl/';
    if (subscriptions) route = 'subscriptions/';
    if (!multiple) {
      // When it's only one field
      const searchPath = cubejs ? `${column.field.toLowerCase()}/` : `${camelCase(column.field)}/`;
      // console.log('PATH', (baseURL + route + searchPath));
      console.log('Current Page', currentPage, maxPages);
      if (currentPage !== 0 && maxPages === currentPage) return;
      const data = await fetch(`${baseURL + route + searchPath}${paginationQuery || ''}`)
        .then((res) => res.json())
        .catch((e) => console.log(e));
      if (data?.results && maxPages === 0) {
        setMaxPage(data.count / limit);
      }
      if (data?.results) {
        setCurrentPage((prevPage) => prevPage + 1);
        setLookupData((prevData) => [...prevData, ...data.results]);
      } else {
        setLookupData(data || data?.results || []);
      }
    } else {
      // Multiple Fields
      const promises = column.map((currentColumn) => {
        const searchPath = cubejs ? `${currentColumn.field.toLowerCase()}/` : `${noCamelCase ? column.field : camelCase(column.field)}/`;
        return fetch(baseURL + route + searchPath)
          .then((res) => res.json())
          .catch((e) => console.log(e));
      });
      // Wait until all the data has loaded
      const data = await Promise.all(promises);
      // Save the Data
      setLookupData(data || []);
    }
  };
  const filterData = () => {
    let data = [];
    const thereIsLookupsData = !isEmpty(lookupData);

    if (thereIsLookupsData) {
      data = lookupData;
    }
    if (isEmpty(filter)) {
      setFilteredData(data);
      return;
    }
    const { values, field } = filter;
    if (lookupData && field && !isEmpty(values)) {
      const newFilteredData = data.filter((row) => {
        if (values.length > 0) {
          return values.includes(row[field]);
        }
        return true;
      });
      setFilteredData(newFilteredData);
    } else if (isEmpty(values)) {
      setFilteredData(data);
    }
  };
  // Everytime the filte changes load the data
  useEffect(() => {
    if (isEmpty(localAutoComplete)) {
      loadData();
    }
  }, []);
  useEffect(() => {
    filterData();
  }, [filter, lookupData]);
  useEffect(() => {
    loadData();
  }, [column]);

  const handleChange = (index) => (values) => {
    // Remove Default All
    let correctValues;
    // Check if its an array
    const isArray = Array.isArray(values);
    // Check if All is present
    const allIndex = isArray && values.findIndex((currentValue) => currentValue === column.defaultValue);
    console.log('Values onChange', values, isArray);
    // console.log('CorrectedValues', correctedValues);
    // If All is no present just set the values
    if (isArray && last(values) === 'loadMore') {
      if (multipleOptions) {
        loadData();
      }
      return;
    }
    if (!isArray || allIndex === -1) {
      correctValues = values;
    }

    // If All is at the Start is in Default Value

    if (isArray && allIndex === 0) {
      // remove it
      values.shift();
      correctValues = values;
    }
    // If All is Set afterwards reset values
    if (isArray && allIndex > 0) {
      correctValues = [column.defaultValue];
    }
    // Avoid undefined and make sure index is a number
    if (!Number.isNaN(index) && typeof index !== 'undefined') {
      // Remove Default ALL
      const newSelectedValues = [...selectedValue];
      newSelectedValues[index] = correctValues;
      // Saves the Values
      // console.log('Selected', newSelectedValues);
      setSelectedValue(newSelectedValues);
      if (onChange) onChange(correctValues);
    } else {
      // console.log('Change', correctedValues);
      // Remove Default All
      setSelectedValue(correctValues);
      if (onChange) onChange(correctValues);
    }
  };

  const selectAfterEffect = (valueToSelect) => {
    switch (afterSelect) {
      case 'reset': {
        setSelectedValue(column.defaultValue);
        break;
      }
      case 'none':
      default: {
        setSelectedValue(valueToSelect);
        break;
      }
    }
  };
  const handleSelect = (index) => (valueSelected) => {
    // Avoid undefined and make sure index is a number
    console.log('Select', valueSelected);
    if (valueSelected === 'loadMore') {
      loadData();
      return;
    }
    if (!Number.isNaN(index) && typeof index !== 'undefined') {
      // Set the Value

      const newSelectedValues = [...valueSelected];
      newSelectedValues[index] = valueSelected;
      selectAfterEffect(newSelectedValues);
    } else {
      if (onSelect) onSelect(valueSelected);
      selectAfterEffect(valueSelected);
    }
  };

  const handleDeselect = (index) => (valueDeselected) => {
    // Avoid undefined and make sure index is a number
    if (!Number.isNaN(index) && typeof index !== 'undefined') {
      // Set the Value
      const newSelectedValues = [...selectedValue];
      newSelectedValues[index] = '';
      setSelectedValue(newSelectedValues);
    } else {
      setSelectedValue('');
      if (onDeselect) onDeselect('Deselect', valueDeselected);
    }
  };
  useEffect(() => {
    if (isEmpty(selectedValue)) {
      setSelectedValue(column.defaultValue);
    }
  }, [selectedValue]);
  useEffect(() => {
    setSelectedValue(multipleOptions ? [value || column.defaultValue] : value || column.defaultValue);
  }, [value]);
  useEffect(() => {
    if (localAutoComplete) {
      setLookupData(localAutoComplete);
    }
  }, [localAutoComplete]);
  console.log('Selected Values', column);
  if (!multiple) {
    return (
      <Select
        value={selectedValue}
        showSearch
        style={{ width: 200 }}
        onSelect={multipleOptions ? null : handleSelect()}
        onDeselect={multipleOptions ? null : handleDeselect()}
        onChange={handleChange()}
        mode={multipleOptions ? 'multiple' : null}
        filterOption={(input, option) => option.children?.toLowerCase()
          .indexOf(input.toLowerCase()) >= 0}
        onSearch={console.log}

      >
        <Option key={column.defaultValue}>{column.defaultValue}</Option>
        {filteredData.map((row) => {
          if (typeof row === 'object') {
            return <Option key={row[column.key]}>{row[column.displayValue || column.key]}</Option>;
          }
          return <Option key={row}>{row}</Option>;
        })}
        {allowNone ? <Option key="none">None</Option> : null}
        {maxPages > 1
        && currentPage !== maxPages
      && <Option key="loadMore">Load More</Option>}
      </Select>
    );
  }
  return column.map((currentColumn, index) => (
    <Select
      value={selectedValue[index]}
      showSearch
      style={{ width: 200 }}
      // onSelect={handleSelect(index)}
      // onDeselect={handleDeselect(index)}
      mode={multipleOptions ? 'multiple' : null}
      onChange={handleChange(index)}
      filterOption={(input, option) => option.children?.toLowerCase()
        .indexOf(input.toLowerCase()) >= 0}
    >
      <Option key={currentColumn.defaultValue}>{currentColumn.defaultValue}</Option>
      {filteredData[index]
        && filteredData[index].map((row) => (
          <Option key={row[currentColumn.key].toString()}>
            {row[currentColumn.displayValue || currentColumn.key]}
          </Option>
        ))}
      {allowNone ? <Option key="none">None</Option> : null}

    </Select>
  ));
};

export default AutoComplete;
