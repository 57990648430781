import { createContext } from 'react';

/**
 * Export to access to the Global CMS Database
 */

const GlobalDatabaseContext = createContext();
const GlobalDatabaseProvider = GlobalDatabaseContext.Provider;

export {
  GlobalDatabaseContext,
  GlobalDatabaseProvider,
};
