import { OAUTH } from '../../../constants';

/** Overview: Save a Temporal Query */
const CMS = `${OAUTH}/api/cms/temporalQueries/`;

const createTemporalQuery = async (exploreQuery, hashCode, timeString) => {
  const result = await fetch(CMS, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      hashId: hashCode,
      ...exploreQuery,
      time_string: timeString[0],

    }),
  }).then((res) => res.json());
  return result;
};

const updateTemporalQuery = async (exploreQuery, hashCode, timeString) => {
  const result = await fetch(`${CMS}${hashCode}/`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      hashId: hashCode,
      ...exploreQuery,
      time_string: timeString[0],
    }),
  }).then((res) => res.json());
  return result;
};

export {
  createTemporalQuery,
  updateTemporalQuery,
};
