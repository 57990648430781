/**
 * @description Actions from the Store to for the Save of the Tabs
 */
import { cloneDeep, isEmpty } from 'lodash';

// We save the Filter in the View
const saveTabFilters = ({ selectedView, views }, query) => {
  // console.log('Selected View', selectedView);
  const { id: selectedViewId } = selectedView;
  // const newViewsPreviousFilters = { ...viewsPreviousFilters };
  // newViewsPreviousFilters[selectedViewId] = query;
  const newViews = [...views];
  const view = newViews.find((currentView) => currentView.id === selectedViewId);
  if (view) view.temp_filters_metadata = query;

  return ({ views: newViews });
};

const setModifiedViewItems = ({ selectedView, modifiedViewItems, clonedViewItems }, viewItems) => {
  const { id: selectedViewId } = selectedView;
  // We clone the View items for the ones to modified and the ones to clone
  const newModifiedViewItems = cloneDeep(modifiedViewItems);
  const newCloned = cloneDeep(clonedViewItems);
  newModifiedViewItems[selectedViewId] = cloneDeep(viewItems);
  newCloned[selectedViewId] = cloneDeep(viewItems);
  // We Set the Values
  return ({ modifiedViewItems: newModifiedViewItems, clonedViewItems: newCloned });
};

const updateDashboardViewItemQuery = ({ selectedView, selectedViewItem, modifiedViewItems }, { id, viewQueries }) => {
  if (isEmpty(modifiedViewItems)) return ({ modifiedViewItems });
  const { id: selectedViewId } = selectedView;
  // We clone the Modified Values
  const newModifiedViewItems = cloneDeep(modifiedViewItems);
  // Grab only the items that we need
  const items = [...newModifiedViewItems[selectedViewId]];
  // Look for the View Item to Modifiy
  const itemToUpdate = items.find((item) => item.id === id);
  if (itemToUpdate) {
    // Save View Queryes
    itemToUpdate.view_queries = viewQueries;
  }

  const newSelectedViewItem = { ...selectedViewItem };
  if (selectedViewItem.id === id) {
    newSelectedViewItem.view_queries = viewQueries;
  }

  return ({ modifiedViewItems: newModifiedViewItems, selectedViewItem: newSelectedViewItem });
};

const updateDashboardQueryGraphConfig = ({ selectedView, modifiedViewItems }, { id, updatedGraphConfig }) => {
  if (isEmpty(modifiedViewItems)) return ({ modifiedViewItems });
  const { id: selectedViewId } = selectedView;
  // We clone the Modified Values

  const newModifiedViewItems = cloneDeep(modifiedViewItems);
  // Grab only the items that we need

  const items = [...newModifiedViewItems[selectedViewId]];
  // Look for the View Item to Modifiy

  const itemToUpdate = items.find((item) => item.id === id);
  if (itemToUpdate) {
    // Save Graph Config
    itemToUpdate.view_queries = itemToUpdate.view_queries.map((viewQuery) => {
      const newViewQuery = viewQuery;
      newViewQuery.graph = updatedGraphConfig;
      return newViewQuery;
    });
  }

  return ({ modifiedViewItems: newModifiedViewItems });
};

export {
  updateDashboardViewItemQuery,
  updateDashboardQueryGraphConfig,
  setModifiedViewItems,
  saveTabFilters,
};
