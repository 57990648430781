import { isEqual } from 'lodash';

const removeFromArray = (arr = [], toRemove) => arr.filter((item) => !isEqual(item, toRemove));

const addToArray = (arr = [], toAdd) => {
  const isInArr = arr.find((item) => isEqual(item, toAdd));
  if (!isInArr) arr.push(toAdd);
  return arr;
};

export {
  removeFromArray,
  addToArray,
};
