import { Alert } from 'antd';
import React, { useState } from 'react';

// eslint-disable-next-line react/prop-types
const DisableSecurity = ({ children, isDisable }) => {
  // By Default there is no security breach
  const [securityBreached, setSecurityBreached] = useState(false);

  const disabledClicked = () => {
    if (isDisable) {
      // If the feature is disable, it will create the breach
      // It doesn't really have to recheck the rule because it was already determined up above
      setSecurityBreached(true);
    }
  };

  // Avoid Overhead if there is no Disable
  if (!isDisable) {
    return children;
  }
  // If the Security hasn't been breach render the component with the event handle
  // Otherwise show an error message that the feature is disabled
  // eslint-disable-next-line jsx-a11y/interactive-supports-focus

  return !securityBreached ? (
    <div style={{
      opacity: !isDisable ? 1 : 0.25,
      pointerEvents: !isDisable ? 'initial' : 'none',
    }}
    >
      {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
      <div onClick={disabledClicked} onKeyDown={disabledClicked} role="button">
        {children}
      </div>
    </div>

  ) : (
    <Alert
      type="error"
      message="Error"
      description="This is a feature that is disable, please contact to your boss so he can enable it"
      showIcon
    />
  );
};

export default DisableSecurity;
