/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { unmountComponentAtNode } from 'react-dom';
// import { useHistory } from 'react-router-dom';
// import { useForm } from 'antd/lib/form/util';
import {
  message,
  Modal,
} from 'antd';
import { clearOutStore } from '../../store/store';

/**
 * Overview: Logout component that is in the navbar of the app to log out a user
 * Has one prop from the store, the one to set the user data to be empty
 */

const Logout = () => {
  // this history variable that makes use of the useHistory hook
  // const history = useHistory();
  // constant to set the modal visible or not
  const [modalVisible, setVisible] = useState(false);

  // function when the user presses the Yes button on the logout modal
  const onLogout = () => {
    // removes the everything from local storage
    localStorage.clear();
    // sets user data to be empty
    // setUserData({});
    // sets the modal to be invisible
    setVisible(false);
    clearOutStore();
    // history push to the login page
    // history.push('/login');
    unmountComponentAtNode(document.getElementById('is-authenticated'));
    message.info('Logout successfully');
    setTimeout(() => window.location.reload(), 500);
  };

  // returns a span that has the click event for the modal and the modal when is visible
  return (
    <div>
      <span onClick={() => setVisible(true)} className="card-center">
        Log Out
      </span>
      <Modal
        key="modal-logout"
        visible={modalVisible}
        onOk={onLogout}
        onCancel={() => setVisible(false)}
        closable={false}
        okText="Yes"
        cancelText="No"
        keyboard={false}
        maskClosable={false}
      >
        <p>Are you sure you want to log out?</p>
      </Modal>
    </div>
  );
};

export default Logout;
