/**
 * Overview: This file creates the store and contains the default state of the store
 */

import { isEmpty } from 'lodash';
import createStore from 'unistore';
import devtools from 'unistore/devtools';

const defaultState = {
  views: [],
  selectedView: {},
  viewItems: {},
  // Stores the View items AFTER Filters, but only the latest version
  modifiedViewItems: {},
  clonedViewItems: {},
  viewsPreviousFilters: {},
  ///
  selectedViewItem: {},
  viewItemContent: {},
  viewItemLayout: {},

  exploreQueryData: {},
  exploreViewItemLayout: [],
  viewItemToBeCreated: {
    settings: {},
    layout: [],
  },
  criteriaSettings: {
    criteria: {
      measures: [],
      dimensions: [],
      filters: {},
      timeDimension: [],
      segments: [],
      Order: [],
    },
    labels: {
      Measure: '',
      Dimension: '',
      Filters: '',
      TimeDimension: '',
      Segments: '',
    },
  },
  editorData: [],
  editorSettings: [],
  // Multiple Charts in 1 View Item
  /**
   *  default it’s value is going to be ‘line’ which is the default chart type
   * Defines the currently set chartType
  */
  chartType: 'line',
  /**
   * Adds to the MixedCharts Variable Object the
   * <shortTitle>: {
   *  chartType : represent the chartType
   *  defaultValue: Is it using the default value?
   * }
   */
  mixedCharts: {},
  /**
   * Explorer Lastest Query will store the data of the latest query
   */
  exploreQuery: {},
  exploreVizState: {},
  exploreCompareVizState: {},
  exploreComparedSettings: {},
  cubejsApi: {},
  /** Update Views */
  updateable: {
    header: [],
    body: [],
    footer: [],
  },
  // Represent the timeString that is going to be used
  timeString: [],
  /* userAvatar is the data from the user that we will get from authentication */
  userAvatar: '' /* { mlsClient: 'IMLS', officeAgentID: '1968', agentID: '30212' } */,
  user: {},

  // Pivot Charts
  customPivot: {},
  // Formater for Ag Grid
  format: {
    date: 'MMMM YYYY',
    ratio: '0.0%',
    other: '0,0',
    currency: '$0,0',
  },
  formatter: {},
  // What the Name of the Variable Says
  shouldUpdateTemporalQuery: false,
  chartsLayout: [],

  resetPasswordMessage: '',
  viewItemPopoverVisible: false,
  mlsSettings: {},
  ssoSettings: [],
  storeHeaderTitle: {},
  /**
   * Secondary Queries they store the filters/dimensions that are beign in used for a query
   * They are an Array
   */
  secondaryQueries: [],
  /**
   * Saves the AmCharts Settings
   */
  amChartsSettings: {},
  statisticsChartsTabs: !isEmpty(localStorage.getItem('statistics-chart-tabs')) ? JSON.parse(localStorage.getItem('statistics-chart-tabs')) : [],
  defaultDashboardTabs: !isEmpty(localStorage.getItem('default-dashboard-ids')) ? JSON.parse(localStorage.getItem('default-dashboard-ids')) : [],
  defaultMapLocation: {},
  updateInitialization: false,
  statisticsChartRendered: false,
  statisticsToggleInitial: localStorage.getItem('statistics-view-mode') ? JSON.parse(localStorage.getItem('statistics-view-mode')) : true,
  dashboardToggleInitial: localStorage.getItem('dashboard-view-mode') ? JSON.parse(localStorage.getItem('dashboard-view-mode')) : false,
  globalDashboards: [],
  publicDashboards: [],
  privateDashboards: [],
  globalCharts: [],
  publicCharts: [],
  privateCharts: [],
  globalCubejsMeta: {},
};

const store = devtools(createStore(defaultState));

const clearOutStore = () => {
  setTimeout(() => {
    store.setState(defaultState);
    // clear 'stuff' after 0.5 second
  }, 500);
};
// store.subscribe((store) => console.log('STORE', store));
export default store;

export {
  clearOutStore,
};
