// universal function to do a request to the django server
// we pass the path of the url to request
// we pass the method
// we pass the headers if we want to change something
// we pass the payload of the request or body
// we pass localhost as TRUE if we want to do request to localhost

import { OAUTH } from "../constants";

// TODO: CHange method, payload and headers to single parameter options and pass as an object with method, payload, headers
/**
 *
 * @param {Boolean} localhost - if True request to localhost
 * @param {String} path - Path to Request without initial /
 * @param {String} method  - HTTP Method for the Request GET, POST, PATCH, DELETE. Default is GET
 * @param {Object} payload - Payload to Send as a plain Object
 * @param {Object} headers - Custom HTTP Header to be added
 * @returns {Promise} - Response from DJANGO
 */
const requestToDjango = async (localhost = false, path = '', method = 'GET', payload, headers) => (
  fetch(!localhost ? `${OAUTH}/${path}` : `http://localhost:8000/${path}`, {
    method,
    headers: headers || {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }).then((res) => method.toUpperCase() !== 'DELETE' && res.json())
);

export default requestToDjango;
