/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
// import logo from './logo.svg';
import './index.less';
import './App.css';

import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
// import client from "./graphql/client";
// new Imports
import { Provider as StoreProvider } from 'unistore/react';
import { store } from './store';
import useSimpleDb from './hooks/useSimpleDb';
import { GlobalDatabaseProvider } from './context/GlobalDatabase';
import { AclProvider, createACLProvider } from './functions/ACLDatabase/AclProvider';
import { AclProviderContextProvider } from './context/AclProviderContext';

export const CubeJsJWT = async () => {
  const refreshToken = store.getState().user;
  if (refreshToken && typeof refreshToken.access === 'object') return refreshToken.access.accessToken;
  return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJGT08iOiJCQVIiLCJpYXQiOjE1ODU3NjExNzgsImV4cCI6MTU4NzQ4OTE3OH0.2LfsWgRoy7Ku-nxfdb4gmaFKOaZT8bzoC86BiYowuYY';
};

// const API_URL = 'http://localhost:3800';
const getUrl = window.location;
let CUBEJS_URL = '';
if (process.env.NODE_ENV === 'development') {
  // CUBEJS_URL = 'http://localhost:3800/cubejs-api/v1';
  CUBEJS_URL = 'https://dev-react.mlsdatatools.com/cubejs-api/v1';
} else {
  CUBEJS_URL = `${getUrl.protocol}//${getUrl.host}/cubejs-api/v1`;
}

const cubejsApi = cubejs(
  async () => CubeJsJWT(),
  // Waits to check auth
  { apiUrl: `${CUBEJS_URL}` },
);

const App = ({ children }) => {
  /**
   * @CUBE-1287 Use the CMS Database
   */
  const [db] = useSimpleDb('cms');
  const [aclProvider, setAclProvider] = React.useState(new AclProvider());
  const init = async () => {
    const newAclProvider = await createACLProvider();
    setAclProvider(newAclProvider);
  };
  useEffect(() => {
    // Initilize the Acl Provider
    init();
    return () => {
      aclProvider.close();
    };
  }, []);

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      <AclProviderContextProvider value={aclProvider}>
        <GlobalDatabaseProvider value={db}>
          <StoreProvider store={store}>
            {children}
          </StoreProvider>
        </GlobalDatabaseProvider>
      </AclProviderContextProvider>

    </CubeProvider>
  );
};

export default App;
