/* eslint-disable no-unused-vars */
import {
  isEmpty, isBoolean,
} from 'lodash';
// Sets new Criteria
const setCriteriaSettings = ({ criteriaSettings },
  newCriteriaSettings) => ({ criteriaSettings: newCriteriaSettings });
const addFilterCriteria = ({ criteriaSettings, viewItems, selectedView }, { title, operator, values }, viewItemId) => {
  let findIndex;
  const newViewItems = viewItems[selectedView.id];
  if (viewItemId) {
    findIndex = viewItems[selectedView.id].findIndex((vi) => vi.id === viewItemId);
  }
  const newCriteria = viewItemId && findIndex >= 0 ? newViewItems[findIndex].settings.criteriaSettings : criteriaSettings;
  let newCriteriaValue;
  if (isEmpty(values)) {
    newCriteriaValue = `${title}: ALL`;
  } else {
    switch (operator) {
      case 'contains':
      case 'equals':
      default: {
        newCriteriaValue = `${title}: ${values.join(',')}`;
        break;
      }
      case 'notContains':
      case 'notEquals': {
        newCriteriaValue = `${title} not in ${values.join(',')}`;
        break;
      }
      case 'gt': {
        newCriteriaValue = `${title} > ${values.join(',')}`;
        break;
      }
      case 'lt': {
        newCriteriaValue = `${title} < ${values.join(',')}`;
        break;
      }
      case 'gte': {
        newCriteriaValue = `${title} >= ${values.join(',')}`;
        break;
      }
      case 'lte': {
        newCriteriaValue = `${title} <= ${values.join(',')}`;
        break;
      }
      case 'ranges': {
        newCriteriaValue = `${title} ${values}}`;
        break;
      }
      case 'inBounds': {
        newCriteriaValue = `${title}: ${values.join(',')}`;
        break;
      }
    }
  }
  newCriteria.criteria.filters[title] = newCriteriaValue;
  const filterValues = Object.values(newCriteria.criteria.filters);
  // newCriteria.labels.Filters = filterValues.length > 0 ? `Filters : ${filterValues.join(',')}` : '';
  const { showLabelString, showLabelValue } = newCriteria.labels.Filters;
  // console.log('Show ', showLabelString, isBoolean(showLabelString), showLabelValue, isBoolean(showLabelValue));
  newCriteria.labels.Filters = {
    label: filterValues.length > 0 ? `Filters : ${filterValues.join(',')}` : '',
    labelString: 'Filters:',
    value: filterValues.length > 0 ? filterValues.join(',') : '',
    showLabelString: isBoolean(showLabelString) ? showLabelString : true,
    showLabelValue: isBoolean(showLabelValue) ? showLabelValue : true,
  };
  if (viewItemId) {
    if (findIndex >= 0) {
      newViewItems[findIndex].settings.criteriaSettings = newCriteria;
      const updateViewItems = viewItems;
      updateViewItems[selectedView.id] = newViewItems;
      return ({
        criteriaSettings: newCriteria,
        viewItems: updateViewItems,
      });
    }
  }
  return ({ criteriaSettings: newCriteria });
};
const removeFilterCriteria = ({ criteriaSettings, viewItems, selectedView }, title, viewItemId) => {
  let findIndex;
  const newViewItems = viewItems[selectedView.id];
  if (viewItemId) {
    findIndex = viewItems[selectedView.id].findIndex((vi) => vi.id === viewItemId);
  }
  const newCriteria = viewItemId && findIndex >= 0 ? newViewItems[findIndex].settings.criteriaSettings : criteriaSettings;
  delete newCriteria.criteria.filters[title];
  const filterValues = Object.values(newCriteria.criteria.filters);
  const { showLabelString, showLabelValue } = newCriteria.labels.Filters;
  newCriteria.labels.Filters = {
    label: filterValues.length > 0 ? `Filters : ${filterValues.join(',')}` : 'Filters : All',
    labelString: 'Filters:',
    value: filterValues.length > 0 ? filterValues.join(',') : 'All',
    showLabelString: isBoolean(showLabelString) ? showLabelString : true,
    showLabelValue: isBoolean(showLabelValue) ? showLabelValue : true,
  };
  if (viewItemId) {
    if (findIndex >= 0) {
      newViewItems[findIndex].settings.criteriaSettings = newCriteria;
      const updateViewItems = viewItems;
      updateViewItems[selectedView.id] = newViewItems;
      return ({
        criteriaSettings: newCriteria,
        viewItems: updateViewItems,
      });
    }
  }
  return ({ criteriaSettings: newCriteria });
};
const addMeasureCriteria = ({
  criteriaSettings, mixedCharts, chartType, viewItems, selectedView,
}, title, viewItemId) => {
  let findIndex;
  const newViewItems = viewItems[selectedView.id];
  if (viewItemId) {
    findIndex = viewItems[selectedView.id].findIndex((vi) => vi.id === viewItemId);
  }
  const newCriteria = viewItemId && findIndex >= 0 ? newViewItems[findIndex].settings.criteriaSettings : criteriaSettings;
  const newMixedCharts = mixedCharts;
  // console.log('new Criteria', newCriteria);
  // Adding multiple titles at the same time
  const newTitles = Array.isArray(title) ? title : [title];
  // Remove Duplicates
  const uniqueMeasures = [...new Set([...newCriteria.criteria.measures, ...newTitles])];
  newCriteria.criteria.measures = uniqueMeasures;
  // newCriteria.labels.Measure = `Measures: ${uniqueMeasures.join(',')}`;
  const { showLabelString, showLabelValue } = newCriteria.labels.Measure;

  newCriteria.labels.Measure = {
    label: `Measures: ${uniqueMeasures.join(',')}`,
    labelString: 'Measures:',
    value: uniqueMeasures.join(','),
    showLabelString: isBoolean(showLabelString) ? showLabelString : true,
    showLabelValue: isBoolean(showLabelValue) ? showLabelValue : true,
  };
  // Generate new value for mixedCharts
  newTitles.forEach((currentTitle) => {
    newMixedCharts[title] = {
      chartType,
      default: true,
    };
  });
  if (viewItemId) {
    if (findIndex >= 0) {
      newViewItems[findIndex].settings.criteriaSettings = newCriteria;
      const updateViewItems = viewItems;
      updateViewItems[selectedView.id] = newViewItems;
      return ({
        criteriaSettings: newCriteria,
        viewItems: updateViewItems,
        mixedCharts: newMixedCharts,
      });
    }
  }
  return ({
    criteriaSettings: newCriteria,
    mixedCharts: newMixedCharts,
  });
};
const removeMeasureCriteria = ({
  criteriaSettings, viewItems, mixedCharts, selectedView,
}, title, viewItemId) => {
  let findIndex;
  const newViewItems = viewItems[selectedView.id];
  if (viewItemId) {
    findIndex = viewItems[selectedView.id].findIndex((vi) => vi.id === viewItemId);
  }
  const newCriteria = viewItemId && findIndex >= 0 ? newViewItems[findIndex].settings.criteriaSettings : criteriaSettings;
  newCriteria.criteria.measures = criteriaSettings.criteria.measures.filter(
    (measure) => measure !== title,
  );
  const measuresValues = newCriteria.criteria.measures;
  const { showLabelString, showLabelValue } = newCriteria.labels.Measure;

  newCriteria.labels.Measure = {
    label: `Measures: ${measuresValues.join(',')}`,
    labelString: 'Measures:',
    value: measuresValues.join(','),
    showLabelString: isBoolean(showLabelString) ? showLabelString : true,
    showLabelValue: isBoolean(showLabelValue) ? showLabelValue : true,
  };
  // delete that this criteria from the mixedChart object
  const newMixedCharts = mixedCharts;
  delete newMixedCharts[title];
  if (viewItemId) {
    if (findIndex >= 0) {
      newViewItems[findIndex].settings.criteriaSettings = newCriteria;
      const updateViewItems = viewItems;
      updateViewItems[selectedView.id] = newViewItems;
      return ({
        criteriaSettings: newCriteria,
        viewItems: updateViewItems,
        mixedCharts: newMixedCharts,
      });
    }
  }
  return ({
    criteriaSettings: newCriteria,
    mixedCharts: newMixedCharts,
  });
};
const addDimensionCriteria = ({ criteriaSettings, viewItems, selectedView }, title, viewItemId) => {
  let findIndex;
  const newViewItems = viewItems[selectedView.id];
  if (viewItemId) {
    findIndex = viewItems[selectedView.id].findIndex((vi) => vi.id === viewItemId);
  }
  const newCriteria = viewItemId && findIndex >= 0 ? newViewItems[findIndex].settings.criteriaSettings : criteriaSettings;
  newCriteria.criteria.dimensions.push(title);
  // newCriteria.labels.Dimensions = `Dimensions: ${newCriteria.criteria.dimensions.join(',')}`;
  const { showLabelString, showLabelValue } = newCriteria.labels.Dimensions || newCriteria.labels.Dimension;

  newCriteria.labels.Dimensions = {
    label: `Dimensions: ${newCriteria.criteria.dimensions.join(',')}`,
    labelString: 'Dimensions:',
    value: newCriteria.criteria.dimensions.join(','),
    showLabelString: isBoolean(showLabelString) ? showLabelString : true,
    showLabelValue: isBoolean(showLabelValue) ? showLabelValue : true,
  };
  if (viewItemId) {
    if (findIndex >= 0) {
      newViewItems[findIndex].settings.criteriaSettings = newCriteria;
      const updateViewItems = viewItems;
      updateViewItems[selectedView.id] = newViewItems;
      return ({
        criteriaSettings: newCriteria,
        viewItems: updateViewItems,
      });
    }
  }

  return ({ criteriaSettings: newCriteria });
};
const removeDimensionCriteria = ({ criteriaSettings, viewItems, selectedView }, title, viewItemId) => {
  let findIndex;
  const newViewItems = viewItems[selectedView.id];
  if (viewItemId) {
    findIndex = viewItems[selectedView.id].findIndex((vi) => vi.id === viewItemId);
  }
  const newCriteria = viewItemId && findIndex >= 0 ? newViewItems[findIndex].settings.criteriaSettings : criteriaSettings;
  newCriteria.criteria.dimensions = criteriaSettings.criteria.dimensions.filter(
    (measure) => measure !== title,
  );
  const dimensionsValues = newCriteria.criteria.dimensions;
  const { showLabelString, showLabelValue } = newCriteria.labels.Dimensions || newCriteria.labels.Dimension;

  newCriteria.labels.Dimensions = {
    label: `Dimensions: ${newCriteria.criteria.dimensions.join(',')}`,
    labelString: 'Dimensions:',
    value: newCriteria.criteria.dimensions.join(','),
    showLabelString: isBoolean(showLabelString) ? showLabelString : true,
    showLabelValue: isBoolean(showLabelValue) ? showLabelValue : true,
  };

  // !isEmpty(dimensionsValues) ? `Dimensions: ${dimensionsValues.join(',')}` : '';

  if (viewItemId) {
    if (findIndex >= 0) {
      newViewItems[findIndex].settings.criteriaSettings = newCriteria;
      const updateViewItems = viewItems;
      updateViewItems[selectedView.id] = newViewItems;
      return ({
        criteriaSettings: newCriteria,
        viewItems: updateViewItems,
      });
    }
  }
  return ({ criteriaSettings: newCriteria });
};
const addSegmentCriteria = ({ criteriaSettings, viewItems, selectedView }, title, viewItemId) => {
  let findIndex;
  const newViewItems = viewItems[selectedView.id];
  if (viewItemId) {
    findIndex = viewItems[selectedView.id].findIndex((vi) => vi.id === viewItemId);
  }
  const newCriteria = viewItemId && findIndex >= 0 ? newViewItems[findIndex].settings.criteriaSettings : criteriaSettings;
  newCriteria.criteria.segments.push(title);
  // newCriteria.labels.Segments = `Segments: ${newCriteria.criteria.segments.join(',')}`;
  const { showLabelString, showLabelValue } = newCriteria.labels.Segments;

  newCriteria.labels.Segments = {
    label: `Segments: ${newCriteria.criteria.segments.join(',')}`,
    labelString: 'Segments:',
    value: newCriteria.criteria.segments.join(','),
    showLabelString: isBoolean(showLabelString) ? showLabelString : true,
    showLabelValue: isBoolean(showLabelValue) ? showLabelValue : true,
  };

  if (viewItemId) {
    if (findIndex >= 0) {
      newViewItems[findIndex].settings.criteriaSettings = newCriteria;
      const updateViewItems = viewItems;
      updateViewItems[selectedView.id] = newViewItems;
      return ({
        criteriaSettings: newCriteria,
        viewItems: updateViewItems,
      });
    }
  }
  return ({ criteriaSettings: newCriteria });
};
const removeSegmentCriteria = ({ criteriaSettings, viewItems, selectedView }, title, viewItemId) => {
  let findIndex;
  const newViewItems = viewItems[selectedView.id];
  if (viewItemId) {
    findIndex = viewItems[selectedView.id].findIndex((vi) => vi.id === viewItemId);
  }
  const newCriteria = viewItemId && findIndex >= 0 ? newViewItems[findIndex].settings.criteriaSettings : criteriaSettings;
  newCriteria.criteria.segments = criteriaSettings.criteria.segments.filter(
    (measure) => measure !== title,
  );
  const segmentValues = newCriteria.criteria.segments;
  const { showLabelString, showLabelValue } = newCriteria.labels.Segments;

  newCriteria.labels.Segments = {
    label: `Segments: ${segmentValues.join(',')}`,
    labelString: 'Segments:',
    value: `${segmentValues.join(',')}`,
    showLabelString: isBoolean(showLabelString) ? showLabelString : true,
    showLabelValue: isBoolean(showLabelValue) ? showLabelValue : true,
  };

  // !isEmpty(segmentValues) ? `Segments: ${segmentValues.join(',')}` : '';

  if (viewItemId) {
    if (findIndex >= 0) {
      newViewItems[findIndex].settings.criteriaSettings = newCriteria;
      const updateViewItems = viewItems;
      updateViewItems[selectedView.id] = newViewItems;
      return ({
        criteriaSettings: newCriteria,
        viewItems: updateViewItems,
      });
    }
  }
  return ({ criteriaSettings: newCriteria });
};
const addTimeDimensionCriteria = ({ criteriaSettings, viewItems, selectedView }, newData, viewItemId) => {
  let findIndex;
  const newViewItems = viewItems[selectedView.id];
  if (viewItemId) {
    findIndex = viewItems[selectedView.id].findIndex((vi) => vi.id === viewItemId);
  }
  const newCriteria = viewItemId && findIndex >= 0 ? newViewItems[findIndex].settings.criteriaSettings : criteriaSettings;
  const { label, ...values } = newCriteria.criteria.timeDimension;
  const correctedData = { ...values, ...newData };

  const dateRangeString = Array.isArray(correctedData.dateRange)
    ? correctedData.dateRange.join(' - ') : correctedData.dateRange;
  /**
   * @CUBE-1346 This allows to create properly the time dimension
   * to avoid showing by undefined or by w/o grouping
   */
  const dateRangeTitle = correctedData.title;
  const labelDateRange = `for ${dateRangeString}`;
  const labelGranularity = correctedData.granularity
    || correctedData.granularity === 'w/o grouping' ? `by ${correctedData.granularity}` : '';
  const newLabel = `Time Range: ${dateRangeTitle} ${labelDateRange} ${labelGranularity}`;
  newCriteria.criteria.timeDimension = {
    ...correctedData,
    label: newLabel,
  };
  const { showLabelString, showLabelValue } = newCriteria.labels.TimeDimension;

  // newCriteria.labels.TimeDimension = newLabel;
  newCriteria.labels.TimeDimension = {
    label: newLabel,
    labelString: 'Time Range:',
    value: `${dateRangeTitle} ${labelDateRange} ${labelGranularity}`,
    showLabelString: isBoolean(showLabelString) ? showLabelString : true,
    showLabelValue: isBoolean(showLabelValue) ? showLabelValue : true,
  };
  if (viewItemId) {
    if (findIndex >= 0) {
      newViewItems[findIndex].settings.criteriaSettings = newCriteria;
      const updateViewItems = viewItems;
      updateViewItems[selectedView.id] = newViewItems;
      return ({
        criteriaSettings: newCriteria,
        viewItems: updateViewItems,
      });
    }
  }
  return ({ criteriaSettings: newCriteria });
};
const updateCriteriaLabel = ({ criteriaSettings }, newLabel, category) => {
  const newCriteria = criteriaSettings;
  switch (category) {
    case 'Measure':
      if (newCriteria.labels.Measure.label) {
        newCriteria.labels.Measure.label = newLabel;
        newCriteria.labels.Measure.value = newLabel;
      } else {
        newCriteria.labels.Measure = newLabel;
      }
      break;
    case 'Dimension':
      if (newCriteria.labels.Dimension.label) {
        newCriteria.labels.Dimension.label = newLabel;
        newCriteria.labels.Dimension.value = newLabel;
      } else {
        newCriteria.labels.Dimension = newLabel;
      }
      break;
    case 'TimeDimension':
      if (newCriteria.labels.TimeDimension.label) {
        newCriteria.labels.TimeDimension.label = newLabel;
        newCriteria.labels.TimeDimension.value = newLabel;
      } else {
        newCriteria.labels.TimeDimension = newLabel;
      }
      break;
    case 'Segments':
      if (newCriteria.labels.Segments.label) {
        newCriteria.labels.Segments.label = newLabel;
        newCriteria.labels.Segments.value = newLabel;
      } else {
        newCriteria.labels.Segments = newLabel;
      }
      break;
    case 'Filters':
      if (newCriteria.labels.Filters.label) {
        newCriteria.labels.Filters.label = newLabel;
        newCriteria.labels.Filters.value = newLabel;
      } else {
        newCriteria.labels.Filters = newLabel;
      }
      break;
    case 'Limit':
      if (newCriteria.labels.Limit.label) {
        newCriteria.labels.Limit.label = newLabel;
        newCriteria.labels.Limit.value = newLabel;
      } else {
        newCriteria.labels.Limit = newLabel;
      }
      break;
    case 'Order':
      if (newCriteria.labels.Order.label) {
        newCriteria.labels.Order.label = newLabel;
        newCriteria.labels.Order.value = newLabel;
      } else {
        newCriteria.labels.Order = newLabel;
      }
      break;
    default:
      break;
  }

  return ({ criteriaSettings: newCriteria });
};

// Make the Remove As the Add
// Keep the same showLabel and ShowValue in the Add and remove from the previous, if it's new then by default is true
const removeTimeDimensionCriteria = ({ criteriaSettings, viewItems, selectedView }, { title, operator, values }, viewItemId) => {
  let findIndex;
  const newViewItems = viewItems[selectedView.id];
  if (viewItemId) {
    findIndex = viewItems[selectedView.id].findIndex((vi) => vi.id === viewItemId);
  }
  const newCriteria = viewItemId && findIndex >= 0 ? newViewItems[findIndex].settings.criteriaSettings : criteriaSettings;
  newCriteria.criteria.measures = criteriaSettings.criteria.measures.filter(
    (measure) => measure !== title,
  );
  // newCriteria.labels.TimeDimension = newLabel;
  if (viewItemId) {
    if (findIndex >= 0) {
      newViewItems[findIndex].settings.criteriaSettings = newCriteria;
      const updateViewItems = viewItems;
      updateViewItems[selectedView.id] = newViewItems;
      return ({
        criteriaSettings: newCriteria,
        viewItems: updateViewItems,
      });
    }
  }
  return ({ criteriaSettings: newCriteria });
};

const updateLimitCriteria = ({ criteriaSettings }, value) => {
  const newCriteria = criteriaSettings;
  newCriteria.criteria.Limit = value;
  newCriteria.labels.Limit = {
    label: `Limit: ${value}`,
    value: `${value}`,
    labelString: 'Limit:',
    showLabelString: true,
    showLabelValue: true,
  };
  return ({ criteriaSettings: newCriteria });
};
const updateOrderCriteria = ({ criteriaSettings },
  orderMembers = [], showLabelString = true, showLabelValue = true) => {
  const newCriteria = criteriaSettings;
  newCriteria.criteria.Order = orderMembers;
  newCriteria.labels.Order = {
    label: `Order by: ${orderMembers.filter((member) => member.order !== 'none')
      .map((current, index) => `${index + 1}. ${current.title} : ${current.order.toUpperCase()}`)
      .join('\n')}`,
    value: `${orderMembers.filter((member) => member.order !== 'none')
      .map((current, index) => `${index + 1}. ${current.title} : ${current.order.toUpperCase()}`)
      .join('\n')}`,
    labelString: 'Order by:',
    showLabelString,
    showLabelValue,
  };
  return ({ criteriaSettings: newCriteria });
};

const emptyCriteria = ({ criteriaSettings }) => {
  const newCriteria = criteriaSettings;
  newCriteria.criteria.Order = [];
  newCriteria.criteria.Limit = '';
  newCriteria.criteria.measures = [];
  newCriteria.criteria.dimensions = [];
  newCriteria.criteria.timeDimension = {};
  newCriteria.criteria.filters = {};
  newCriteria.criteria.segments = [];
  newCriteria.labels.Order = {};
  newCriteria.labels.Measure = {};
  newCriteria.labels.Dimension = {};
  newCriteria.labels.TimeDimension = {};
  newCriteria.labels.Filters = {};
  newCriteria.labels.Limit = {};
  newCriteria.labels.Segments = {};
  return ({ criteriaSettings: newCriteria });
};

export default {
  removeTimeDimensionCriteria,
  setCriteriaSettings,
  addFilterCriteria,
  addMeasureCriteria,
  addSegmentCriteria,
  addDimensionCriteria,
  addTimeDimensionCriteria,
  updateCriteriaLabel,
  removeDimensionCriteria,
  removeFilterCriteria,
  removeMeasureCriteria,
  removeSegmentCriteria,
  updateLimitCriteria,
  updateOrderCriteria,
  emptyCriteria,
};
