/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Menu } from 'antd';
import styled from 'styled-components';

import ACLWrapper from './ACLWrapper';
import GenericDropdownButton from '../GenericDropdownButton';
import ACLSettings from './ACLSettings';

// Styles for the Links of the Header
const LinkWrapper = styled(GenericDropdownButton)`
&& {
    top: 4px;
    border-bottom: 4px solid transparent;
    &:hover {
      border-bottom: 4px solid transparent;
      fill: currentColor;
      & > a {
        color: #ffffff;
        opacity: 1;
      }
      & > span {
         fill-opacity: 1;
      }
    }
  }
  .ant-menu-item-selected  &&
  {
    color: white;
    border-bottom: 4px solid white;
    & > a {
      opacity: 1;
    }
    & > span {
       fill: currentColor;
      fill-opacity: 1;
    }
  }
  && > a {
    color: #ffffff;
    opacity: 0.60;
    font-weight: bold;
    letter-spacing: 0.01em;
    padding-right: 10px;
  }

  && > span {
    color: white;
    fill: currentColor;
    fill-opacity: 0.6;
  }
 
`;

const ACLButtonWrapper = ({
  name, type, icon = <></>, button, children, onClick, link, overwriteStyles, className,
}) => {
  // Open the Modal for ACL Settings
  const [openModal, settingModalOpen] = useState(false);

  // Add Styles to the GenericDropdownButton if its a Link
  const GenericComponent = link && !overwriteStyles ? LinkWrapper : GenericDropdownButton;

  // If it received a Valid JSX Component it will use it instead of a Button
  const useCustomComponent = React.isValidElement(button) ? {
    // eslint-disable-next-line no-unused-vars
    buttonsRender: ([_, __]) => [
      button, icon,
    ],
  } : false;

  return (
    <div className={className}>
      <ACLWrapper itemType="ActionButton" name={name}>
        {(isOwner) => [
          // eslint-disable-next-line no-nested-ternary
          isOwner ? (
            /**          If its the Owner show the View for Ownership (Dropdown button) */
            <GenericComponent
              key={`${name}-acl-generic-component`}
              onClick={onClick}
              type={type}
              icon={icon}
              menu={() => (
                <Menu>
                  <Menu.Item key="acl-button-wrapper-settings" onClick={() => settingModalOpen(true)}>Settings</Menu.Item>
                </Menu>
              )}
              // This Added the Custom Button if it's present
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...useCustomComponent}
            >
              {/* If it's a String it will the text of the button */}
              {!useCustomComponent && children}
            </GenericComponent>

          ) : (
          /**   // If its a Custom Component it uses the button that it received as the View
            // Otherwise it created the button */
            useCustomComponent ? button : (
              <Button key={`${name}-button`} onClick={onClick}>
                {children}
              </Button>
            )
          ),
          // Modal with  the ACL Settings
          <ACLSettings
            key={`acl-setting-button-${name}`}
            setACLSettings={settingModalOpen}
            settingsVisible={openModal}
            enable={false}
            title={name}
          />,
        ]}
      </ACLWrapper>
    </div>
  );
};

export default ACLButtonWrapper;
