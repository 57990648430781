/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/**
 * Overview: This File contains the actions/operations for the data in the store
 */

import {
  isEmpty, difference, merge, isEqual,
  cloneDeep,
  uniqBy,
  isNumber,
} from 'lodash';
import jwt from 'jwt-decode';

import store from './store';
import { updateTemporalQuery, createTemporalQuery } from '../components/Charts/Utils/saveQuery';
import { MULTIPLE_CHART } from '../components/Charts/AmCharts/typeOfChartFlags';
import {
  CriteriaActions, updateDashboardViewItemQuery, updateDashboardQueryGraphConfig, setModifiedViewItems, saveTabFilters,
} from './ActionsDir';
import { CMS } from '../constants';
import requestToDjango from '../functions/requestToDjango';
// const CMS = 'https://oauth2.mlsdatatools.com/api/cms';
// const CMS = 'http://localhost:8000/api/cms';

const actions = {
  getFullView: async ({ selectedView, viewItems }, paramView) => {
    let newSelectedView;
    // await fetch(`http://localhost:8000/api/cms/views-inverted-filter/?name=${paramView}&enabled=true`)
    await fetch(`${CMS}/views-inverted-filter/?name=${paramView}&enabled=true`)
      .then((res) => res.json())
      .then((data) => {
        [newSelectedView] = data;
      });
    if (newSelectedView?.id) {
      const newViewItemsForView = newSelectedView.views_items;
      const newViewItems = viewItems;
      newViewItems[newSelectedView.id] = uniqBy(newViewItemsForView, 'id');
      return ({ selectedView: newSelectedView, viewItems: newViewItems });
    }
    return ({ selectedView, viewItems });
  },
  getSelectedViewItem: async ({ selectedViewItem }, paramItem) => {
    let newSelectedItem;

    await fetch(`${CMS}/viewsItems/?uuid=${paramItem}&enabled=true`)
      .then((res) => res.json())
      .then((data) => {
        [newSelectedItem] = data;
      });
    if (newSelectedItem?.id) {
      return ({ selectedViewItem: newSelectedItem });
    }
    return ({ selectedViewItem });
  },
  setSelectedViewItem: (_, newSelectedItem) => {
    const item = newSelectedItem;
    if (item.enabled === false) {
      item.enabled = true;
      requestToDjango(false, `api/cms/viewsItems/${item.id}/`, 'PATCH', { enabled: true });
    }
    return ({ selectedViewItem: item });
  },
  /**
     * The first parameter is the store
     * The Second paramater is what we want from the view
     */
  getViews: ({ views }, queriedViews = [], paramView) => {
    // if there is and id (in params basically)
    const uniqueViews = uniqBy(queriedViews, 'id');
    if (paramView) {
      // finds and stores the selected view
      const newSelectedView = uniqueViews.find((view) => view.name === paramView);
      return ({ views: uniqueViews, selectedView: newSelectedView || {} });
    }
    return ({ views: uniqueViews });
  },
  /**
   * @CUBE-1287 We added receiving Values in Set View Items
   */
  setViewItems: ({ viewItems, selectedView, user }, values, viewId) => {
    // This is the current state of the whole store as an object containing all stored values.
    const updateStore = store.getState();
    // sets the view items from the store if there are ones
    if (selectedView?.id === updateStore.selectedView?.id) {
      // console.log('Received View Items', values);
      //  @CUBE-1287 If we Receive Values we set them
      const newViewItems = viewItems;
      if (values) {
        newViewItems[viewId || selectedView.id] = values;
        return ({ viewItems: newViewItems });
      }
      //  @CUBE-1287 Backwards compatibility to allow the usa of localhost as new database is empty
      const value = localStorage.getItem(`viewItems-${selectedView.id}`);
      if (value !== 'undefined') {
        newViewItems[viewId || selectedView.id] = JSON.parse(value);
        return ({ viewItems: newViewItems });
      }
    }
  },
  resetViewItems: ({ viewItems, selectedView }, newViewItems = []) => ({ viewItems: newViewItems }),
  // @CUBE-1287 No Longer Needed
  // eslint-disable-next-line consistent-return
  // getViewItems: async ({ viewItems, selectedView, user }, queriedViewItems) => {
  //   const viewItemsFromLocal = user.access && localStorage.getItem(`viewItems-${selectedView.id}`);
  //   let viewItemsFromLocalParsed;
  //   if (viewItemsFromLocal && viewItemsFromLocal !== 'undefined') {
  //     viewItemsFromLocalParsed = JSON.parse(localStorage.getItem(`viewItems-${selectedView.id}`));
  //   }
  //   // This is the current state of the whole store as an object containing all stored values.
  //   const updateStore = store.getState();
  //   const itemsData = await Promise.all(
  //     selectedView.views_items.map((id) => fetch(`${CMS}/viewsItems/?id=${id}&enabled=true`)),
  //   )
  //     .then((res) => Promise.all(res.map((r) => r.json())))
  //     .then((res) => res.map((r) => {
  //       const [item] = r;
  //       return item;
  //     }))
  //     .then((res) => res.filter((r) => !isEmpty(r)))
  //     .catch((e) => console.log(e));
  //   if (!viewItemsFromLocal
  //   || !isEqual(viewItemsFromLocalParsed, itemsData)) {
  //     try {
  //       localStorage.setItem(`viewItems-${selectedView.id}`, JSON.stringify(itemsData));
  //     } catch (e) {
  //       // we should handle deletion of localstorage to refresh it
  //       console.log(e);
  //     }
  //     if (selectedView?.id === updateStore.selectedView?.id) {
  //       return ({ viewItems: itemsData });
  //     }
  //   }
  // },
  createView: ({ views }, newView) => {
    // stores what it will be the new views
    const newViews = [...views];
    // pushes the newly created view to the array of views
    newViews.push(newView);
    const uniqueViews = uniqBy(newViews, 'id');
    // sorts the new views based on the id just in case they get disorganized with state
    // newViews.sort((a, b) => a.id - b.id);
    return ({ views: uniqueViews });
  },
  // eslint-disable-next-line consistent-return
  selectView: ({ views, selectedView }, id, resetFilters) => {
    /* if (selectedView && !isEmpty(selectedView)) {
      const selectedViewIndex = views.findIndex((savedView) => selectedView.id === savedView.id);
      const newViews = [...views];
      const newSelectedView = views.find((view) => view.id === parseInt(id, 10));
      newViews[selectedViewIndex] = {
        ...newViews[selectedViewIndex],
        ...selectedView,
      };
      return { views: newViews, selectedView: newSelectedView };
    } */
    // stores the selected view based on the id we passed to it
    const newSelectedView = views.find((view) => view.id === parseInt(id, 10));
    // if it found the view then it will set that as the new selectedView
    if (newSelectedView) {
      const { header, body, footer } = newSelectedView;
      if (resetFilters) {
        newSelectedView.filter = {};
      }
      return ({
        selectedView: newSelectedView,
        updateable: {
          header: header.update,
          body: body.update,
          footer: footer.update,
        },
      });
    }
    // if it didn't found the view it will set and
    // empty object as the new selected view (for the home tab)
    if (!newSelectedView) {
      return ({ selectedView: {} });
    }
  },
  updateSelectedView: async ({
    selectedView, views, viewItems, clonedViewItems, modifiedViewItems,
  }, updatedView, notUpdateViewItems) => {
    // stores what it will replace from the current view
    const replaceView = {
      ...selectedView,
      ...updatedView,
      // id: selectedView.id,
    };

    // stores the index of the view we are going to change
    const updateViewIndex = views.findIndex((savedView) => replaceView.id === savedView.id);
    // stores the array of views
    const newViews = [...views];
    // changes the views based on the updateViewIndex and replace with the changes of the
    // selected view
    newViews[updateViewIndex] = {
      ...newViews[updateViewIndex],
      ...replaceView,
    };

    const uniqueViews = uniqBy(newViews, 'id');
    const newCloned = { ...clonedViewItems };
    const newModified = { ...modifiedViewItems };
    // console.log('Selected View', selectedView?.views_items.length, updatedView?.views_items.length, notUpdateViewItems);
    if ((selectedView?.views_items.length - updatedView?.views_items.length) !== 0 && notUpdateViewItems) {
      newCloned[selectedView.id] = [];
      newModified[selectedView.id] = [];
      return ({
        selectedView: replaceView,
        views: uniqueViews,
        clonedViewItems: newCloned,
        modifiedViewItems: newModified,
      });
    }
    if ((selectedView?.views_items.length - updatedView?.views_items.length) !== 0 && !notUpdateViewItems) {
      const itemsData = await Promise.all(
        updatedView.views_items.map((id) => fetch(`${CMS}/viewsItems/${id}/`)),
      )
        .then((res) => Promise.all(res.map((r) => r.json())))
        .catch((e) => console.log(e));

      const updateStore = store.getState();
      if (selectedView?.id === updateStore.selectedView?.id) {
        const newViewItems = viewItems;
        newViewItems[selectedView.id] = itemsData;
        newCloned[selectedView.id] = cloneDeep(newViewItems[selectedView.id]);
        newModified[selectedView.id] = cloneDeep(newViewItems[selectedView.id]);
        return ({
          viewItems: newViewItems,
          views: uniqueViews,
          selectedView: replaceView,
          clonedViewItems: newCloned,
          modifiedViewItems: newModified,
        });
      }
    }
    return ({ selectedView: replaceView, views: uniqueViews });
  },
  // addViewItemsToSelectedView
  // This might not work with the new improvmenets I am planning
  // TODO: CHECK IF THIS IS WORKING PROPERLY AFTER FINISHING CHANGES FOR viewItems global var;
  changeViewItems: async ({
    selectedView, viewItems, clonedViewItems, modifiedViewItems,
  }, ids) => {
    const newSelectedView = selectedView;
    const oldsIds = newSelectedView.views_items;
    newSelectedView.views_items = ids;
    const newCloned = { ...clonedViewItems };
    const newModified = { ...modifiedViewItems };
    newCloned[selectedView.id] = [];
    newModified[selectedView.id] = [];
    return ({ selectedView: newSelectedView, clonedViewItems: newCloned, modifiedViewItems: newModified });
  },
  removeViewItem: async ({
    selectedView, viewItems, clonedViewItems, modifiedViewItems,
  }, id) => {
    const updatedView = await fetch(`${CMS}/views/${selectedView.id}/`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        views_items: [id],
        remove: true,
      }),
    });
    const newCloned = { ...clonedViewItems };
    const newModified = { ...modifiedViewItems };
    const newSelectedView = selectedView;
    newSelectedView.views_items = newSelectedView.views_items.filter((viewItem) => viewItem !== id);
    const newViewItems = viewItems;
    const viewItemsFromView = newViewItems[selectedView.id];
    const viewItemChange = viewItemsFromView.filter((viewItem) => viewItem.id !== id);
    newViewItems[selectedView.id] = viewItemChange;
    newModified[selectedView.id] = cloneDeep(newViewItems[selectedView.id]);
    newCloned[selectedView.id] = cloneDeep(newViewItems[selectedView.id]);

    return ({
      selectedView: newSelectedView, viewItems: newViewItems, clonedViewItems: newCloned, modifiedViewItems: newModified,
    });
  },
  updateViews: ({ views }, updatedView) => {
    // stores the index of the view with the same id
    const updateViewIndex = views.findIndex((savedView) => updatedView.id === savedView.id);
    // stores the views
    const newViews = [...views];
    // replace the content of the view I want with the new content of the view
    newViews[updateViewIndex] = {
      ...newViews[updateViewIndex],
      ...updatedView,
    };
    const uniqueViews = uniqBy(newViews, 'id');
    return ({ views: uniqueViews });
  },
  updateViewItems: ({
    viewItems, selectedView, modifiedViewItems, clonedViewItems,
  }, updatedItem) => {
    const newCloned = { ...clonedViewItems };
    const newModified = { ...modifiedViewItems };
    const updateViewItemIndex = viewItems[selectedView.id]?.findIndex(
      (savedViewItem) => updatedItem.id === savedViewItem.id,
    );
    const newViewItems = viewItems;
    if (selectedView.id) {
      newViewItems[selectedView.id] = [...newViewItems[selectedView.id]];
      newViewItems[selectedView.id][updateViewItemIndex] = {
        ...newViewItems[selectedView.id][updateViewItemIndex],
        ...updatedItem,
      };
      newModified[selectedView.id] = cloneDeep(newViewItems[selectedView.id]);
      newCloned[selectedView.id] = cloneDeep(newViewItems[selectedView.id]);
    } else {
      Object.keys(newViewItems).forEach((key) => {
        const indexForUpdate = newViewItems[key].find((viewItem) => viewItem.id === updatedItem.id);

        if (indexForUpdate) {
          newViewItems[selectedView.id][indexForUpdate] = {
            ...newViewItems[selectedView.id][indexForUpdate],
            ...updatedItem,
          };
          newModified[selectedView.id] = cloneDeep(newViewItems[selectedView.id]);
          newCloned[selectedView.id] = cloneDeep(newViewItems[selectedView.id]);
        }
      });
    }

    return ({ viewItems: newViewItems, clonedViewItems: newCloned, modifiedViewItems: newModified });
  },
  removeView: ({ views }, view) => {
    // @TODO
  },
  enableViews: ({ views }, viewsIds = []) => {
    // stores the new views that are going to be enabled
    // based on the viewsIds we recieve
    const newViews = views.map((savedView) => {
      const updatedView = savedView;
      // if the ids include the ids of the mapped views
      if (viewsIds.includes(updatedView.id)) {
        // console.log('Updating ', updatedView.id);
        // sets the view to enabled = true
        updatedView.enabled = true;
      }
      return updatedView;
    });
    const uniqueViews = uniqBy(newViews, 'id');

    return ({ views: uniqueViews });
  },
  // add view item to views
  addViewItemToViews: ({ views, modifiedViewItems, clonedViewItems }, viewItemId, viewsAffected) => {
    const newViews = views.map((view) => {
      // If the view id is in the affected views it will modify it
      if (viewsAffected.includes(view.id)) {
        view.views_items.push(viewItemId);
      }
      return view;
    });
    // In which views we are adding the new items
    const uniqueViews = uniqBy(newViews, 'id');
    const viewsId = uniqueViews.map((view) => view.id);
    // We have to reset cloned items and the modified versions, because we don't have the new values loaded
    const newCloned = { ...clonedViewItems };
    const newModified = { ...modifiedViewItems };
    viewsId.forEach((id) => {
      newCloned[id] = [];
      newModified[id] = [];
    });
    return ({ views: uniqueViews, modifiedViewItems: newModified, clonedViewItems: newCloned });
  },
  // action for the content of the viewitems (in this case tags for header and footer)
  setViewItemContent: ({ viewItemContent }, content = {}) => ({ viewItemContent: content }),
  // action for the layout of the viewItems (when updating to the explorePage)
  setViewItemLayout: ({ viewItemLayout }, item = {}) => ({ viewItemLayout: item }),
  addUpdateableItem: ({ updateable }, { tag, viewItemId }) => {
    const newType = updateable[tag];
    newType.push(viewItemId);
    return ({
      ...updateable,
      [tag]: newType,
    });
  },
  setQueryData: ({ exploreQueryData }, queryData) => ({ exploreQueryData: queryData }),
  setExploreLayout: ({ exploreViewItemLayout },
    newLayout) => ({ exploreViewItemLayout: newLayout }),

  // Criteria
  ...CriteriaActions,
  // Sets the Layout of the View Item to be Created
  setLayout: ({ viewItemToBeCreated }, layout) => {
    const newViewItemToBeCreated = viewItemToBeCreated;
    newViewItemToBeCreated.layout = layout;
    return ({ viewItemToBeCreated: newViewItemToBeCreated });
  },
  // Sets the Settings of the View Item to be created
  setSettings: ({ viewItemToBeCreated }, settings) => {
    const newViewItemToBeCreated = viewItemToBeCreated;
    newViewItemToBeCreated.settings = merge({}, viewItemToBeCreated.settings, settings);
    return ({ viewItemToBeCreated: newViewItemToBeCreated });
  },
  // Am Chart Editor
  setEditorData: ({ editorData }, newEditorData, index, chartType) => {
    if (chartType === MULTIPLE_CHART) {
      const newEditor = Array.isArray(editorData) ? editorData : [];
      if (!newEditor[index]) {
        newEditor.push(newEditorData);
      } else {
        newEditor[index] = newEditorData;
      }
      return ({ editorData: newEditor });
    }
    return ({ editorData: newEditorData });
  },
  // This Function is to set the Configuration of the HeatMap
  setHeatMapConfig: ({ editorSettings }, config, index) => {
    // console.log('Chart Type Flag', chartType);
    // console.log(logMsg)

    const newEditor = Array.isArray(editorSettings) ? cloneDeep(editorSettings) : [];
    // console.log('Old Editor Setting Set', JSON.stringify(newEditor));
    if (!newEditor[index]) {
      // console.log('Adding New Data in index', index);
      if (config && isNumber(index)) newEditor.push(config); // actions setHeatMapConfig
    } else {
      // console.log('Updating', index);
      newEditor[index] = config;
    }
    // console.log('After Update Editor Setting Set', JSON.stringify(newEditor));
    // console.log('After Update Length', newEditor.length);
    // console.log('New Editor Settings', newEditorData);
    return ({ editorSettings: newEditor });
  },
  /**
  *  setEditorConfig: ({ editorSettings }, newEditorConfig, sender) => {
    console.log(`Sender ${sender} , payload: ${JSON.stringify(newEditorConfig)}`);
    return ({ editorSettings: newEditorConfig });
  },
  */
  setAbsoluteEditorConfig: ({ editorSettings }, graphConfig) => ({ editorSettings: graphConfig }),
  setEditorConfig: ({ editorSettings }, newEditorData, index, logMsg) => {
    // console.log('Chart Type Flag', chartType);
    // console.log(logMsg)
    // console.log('New Editor Data', newEditorData);
    const newEditor = Array.isArray(editorSettings) ? cloneDeep(editorSettings) : [];
    // console.log('Old Editor Setting Set', JSON.stringify(newEditor));
    // console.log('Index', index);
    if (!newEditor[index]) {
      // console.log('Adding New Data in index', index);
      if (newEditorData && isNumber(index)) newEditor.push(newEditorData);
    } else {
      // console.log('Updating', index);
      newEditor[index] = newEditorData;
    }
    // console.log('After Update Editor Setting Set', JSON.stringify(newEditor));
    // console.log('After Update Length', newEditor.length);
    // console.log('New Editor Settings', newEditor);
    return ({ editorSettings: newEditor });

    // console.log('Normal Editor');
  },

  // Multiple Charts Types in 1 View Item
  setChartType: ({ mixedCharts, chartType }, newChartType) => {
    const newMixedCharts = Object.entries(mixedCharts).reduce((result, [key, value]) => {
      const updatedMixedCharts = result;
      // If the value is using the default chart update it value
      if (value.default) {
        updatedMixedCharts[key] = {
          ...value,
          chartType: newChartType,
        };
      } else {
        // Keep their values if not
        updatedMixedCharts[key] = value;
      }
      return updatedMixedCharts;
    }, {});
    // Set the State of the store and reset the Editor Settings
    return ({ chartType: newChartType, mixedCharts: newMixedCharts, editorSettings: {} });
  },
  // resetChartType
  resetChartType: (_) => ({ chartType: 'line' }),
  // Modify an existing value
  /**
   * received mixedCharts as a value from the store, and two other parameter title and chartType
   */
  modifyMixedCharts: ({ mixedCharts }, title, chartType) => {
    // Create Shawllow copy of mixed charts
    const newMixedCharts = { ...mixedCharts };
    // Update the Selected Value
    newMixedCharts[title] = {
      chartType,
      default: false,
    };
    // When the mixedCharts changes reset the editor
    return ({ mixedCharts: newMixedCharts, editorSettings: {} });
  },
  // reset mixed chart
  resetMixedCharts: (_) => ({ mixedCharts: {} }),
  // set mixed charts on explore query builder
  setMixedCharts: (_, newMixedCharts) => ({ mixedCharts: newMixedCharts || {} }),
  /**
   * First parameter is a destructured store
   */
  setExploreQuery: ({
    chartType,
    exploreViewItemLayout,
    editorSettings,
    criteriaSettings,
    mixedCharts,
    exploreVizState,
    exploreCompareVizState,
    exploreComparedSettings,
  }) => {
    const explorerQuery = {
      chartType,
      exploreViewItemLayout,
      criteriaSettings,
      mixedCharts,
      exploreVizState,
      exploreCompareVizState,
      exploreComparedSettings,
    };
    localStorage.setItem('explorer-latest-query', JSON.stringify(explorerQuery));
    // console.log('Saved', JSON.stringify(explorerQuery));
    return ({
      exploreQuery: explorerQuery,

    });
  },
  resetExplorerValues: (_) => ({
    chartType: 'line',
    exploreViewItemLayout: [],
    mixedCharts: {},
    exploreVizState: {},
    exploreCompareVizState: {},
    exploreComparedSettings: {},
  }),
  setStoredExploreQuery: async ({ exploreQuery }, newExploreQuery) => {
    if (!isEqual(exploreQuery, newExploreQuery) && !newExploreQuery.detail) {
      const {
        chartType,
        exploreViewItemLayout,
        criteriaSettings,
        mixedCharts,
        exploreVizState,
        exploreCompareVizState,
        exploreComparedSettings,
      } = newExploreQuery;

      return ({
        exploreQuery: newExploreQuery,
        chartType: chartType || exploreQuery.chartType,
        exploreViewItemLayout,
        criteriaSettings: criteriaSettings || exploreQuery.criteriaSettings,
        mixedCharts: mixedCharts || exploreQuery.mixedCharts,
        exploreVizState,
        exploreCompareVizState,
        exploreComparedSettings: exploreComparedSettings || exploreQuery.exploreComparedSettings,
      });
    }
  },
  getExploreQuery: ({ exploreQuery }) => {
    if (isEmpty(exploreQuery)) {
      const latestFromLocal = localStorage.getItem('explorer-latest-query');
      // console.log('Latest from local', latestFromLocal);
      const newExploreQuery = JSON.parse(latestFromLocal);
      const {
        chartType,
        exploreViewItemLayout,
        criteriaSettings,
        mixedCharts,
        exploreVizState,
        exploreCompareVizState,
        exploreComparedSettings,
      } = newExploreQuery;

      return ({
        exploreQuery: newExploreQuery,
        chartType,
        exploreViewItemLayout,
        criteriaSettings,
        mixedCharts,
        exploreVizState,
        exploreCompareVizState,
        exploreComparedSettings,
      });
    }
    const {
      chartType,
      exploreViewItemLayout,
      criteriaSettings,
      mixedCharts,
      exploreVizState,
      exploreCompareVizState,
      exploreComparedSettings,
    } = exploreQuery;

    return ({
      exploreQuery,
      chartType,
      exploreViewItemLayout,
      criteriaSettings,
      mixedCharts,
      exploreVizState,
      exploreCompareVizState,
      exploreComparedSettings,
    });
  },
  setExploreVizState: (_, newVizState) => ({ exploreVizState: newVizState }),
  setExploreCompareVizState: (_, newComparedVizState) => ({
    exploreCompareVizState: newComparedVizState,
  }),

  setExploreComparedSettings: (_, newComparedSettings) => ({
    exploreComparedSettings: newComparedSettings,
  }),
  setCubeJSApi: (_, cubejsApi) => ({ cubejsApi }),
  /** gets the user data to have acl management and media */
  setUserData: ({ user, userAvatar }, userToken = {}, singleSignOn = false, anonymous = false, print = false) => {
    // if the userToken is not empty
    // console.log('User Token', userToken);
    if (!isEmpty(userToken)) {
      // will store the decoded access token
      let decodedToken;
      // will store the decoded refresh token
      let decodedRefresh;
      // stores the userData to be user
      const userData = { ...user };
      const userTokenModified = userToken;
      userData.singleSignOn = userToken.singleSignOn || singleSignOn;
      userTokenModified.singleSignOn = singleSignOn;
      try {
        // decodes the access & refresh token to get the user information
        decodedToken = jwt(userToken.access);
        decodedRefresh = jwt(userToken.refresh);
        // valid token format
      } catch (error) {
        // invalid token format
      }
      // if the access token and the refresh token does not exists together with the user not being empty
      if (decodedToken && !decodedRefresh && !isEmpty(user)) {
        // this will be the updated token with the same refresh token and new access token
        const updatedToken = {
          access: userToken.access,
          refresh: user.refresh.refreshToken,
          singleSignOn: user.singleSignOn,
        };
        // saved the token in local storage
        if (!anonymous) localStorage.setItem('user-token', JSON.stringify(updatedToken));
      }
      // if both decoded tokens exist then it will save the userToken to local storage
      if (decodedRefresh && decodedToken && !anonymous) localStorage.setItem('user-token', JSON.stringify(userTokenModified));
      // if decodedToken exists
      if (decodedToken) {
        // sets the userData access prop to be the following
        userData.access = {
          accessToken: userToken.access,
          ...decodedToken,
        };
      }
      // if decodedRefresh exists
      if (decodedRefresh) {
        // sets the userData refresh prop to be the following
        userData.refresh = {
          refreshToken: userToken.refresh,
          ...decodedRefresh,
        };
      } else {
        // this is to avoid deleting the refresh token by accident
        userData.refresh = {
          ...user.refresh,
        };
      }
      // if we found a url and doesn't have http but it has //
      if (decodedToken.avatar_url && !decodedToken.avatar_url.includes('http') && decodedToken.avatar_url.includes('//')) {
        // we add the http to the url
        decodedToken.avatar_url = `http:${decodedToken.avatar_url}`;
      }
      // if the avatar url exists
      if (decodedToken.avatar_url) {
        // stores an image element
        const s = document.createElement('IMG');
        // assign it the source of that element to be the avatar url
        s.src = decodedToken.avatar_url;
        // if there is an error establishing the image
        s.onerror = () => {
          // we set the state avatar url to be null
          // NOte: Here we can also set things like a default message error trhough a state variable
          decodedToken.avatar_url = null;
        };
      }
      return ({ user: userData, userAvatar: decodedToken.avatar_url });
    }
    console.log('Print', print);
    if (print) return ({ user: { access: { mmsc: 3 } } });

    return ({ user: {} });
  },
  /* setUserAccessToken: ({ user, userAvatar }, accessToken) => {
    if (user.access) {
      const newData = user;
      let decodedAccess;
      try {
        decodedAccess = jwt(accessToken);
      } catch (error) {
        // Here we can log errors
      }
      if (decodedAccess) {
        newData.access = {
          accessToken,
          ...decodedAccess,
        };
        // if the avatar url exists
        if (decodedAccess.avatar_url) {
          // stores an image element
          const s = document.createElement('IMG');
          // assign it the source of that element to be the avatar url
          s.src = decodedAccess.avatar_url;
          // if there is an error establishing the image
          s.onerror = () => {
            // we set the state avatar url to be null
            // NOte: Here we can also set things like a default message error trhough a state variable
            decodedAccess.avatar_url = null;
          };
        }
        const updatedToken = {
          access: newData.access.accessToken,
          refresh: newData.refresh.refreshToken,
        };
        localStorage.setItem('user-token', JSON.stringify(updatedToken));
      }
      return ({ user: newData, userAvatar: decodedAccess.avatar_url });
    }
    return ({ user: {} });
  }, */
  setUserDataSaml: ({ user }, values) => ({ user: values }),
  setStoreTimeString: ({ timeString }, newTimeString, index) => {
    const newTimeStrings = [...timeString];
    // Prevent Taking 0 as not existing
    if (index !== undefined) {
      // Update the Value
      newTimeStrings[index + 1] = newTimeString;
    } else {
      // Update the Value
      newTimeStrings[0] = newTimeString;
    }
    // Update hte value in the store
    return ({ timeString: newTimeStrings });
  },
  setUserAvatar: (_, newUserAvatar) => ({ userAvatar: newUserAvatar }),
  /** gets the user data to set its avatar  */
  /* getUserAvatar: ({ userAvatar }, userData) => {
    const user = { mlsClient: userData.mlsClient, officeAgentID: userData.officeAgentID, agentID: userData.agentID };
    return ({ userAvatar: user });
  }, */

  // Custom Pivot
  setCustomPivot: (_, newPivot) => ({ customPivot: newPivot }),
  // Format for the Table
  // Save the Format that is going to be used
  saveFormat: ({ format }, newFormat) => ({ format: newFormat }),
  // Saves the object of format functions currently in place
  saveFormatter: ({ formatter }, newFormater) => ({ formatter: newFormater }),
  setUpdateTemporalQuery: ({ shouldUpdateTemporalQuery }, update) => ({ shouldUpdateTemporalQuery: update }),
  // Save Temporal Query
  saveTemporalQuery: ({ shouldUpdateTemporalQuery, exploreQuery, timeString }, hash, setUpdate = false) => {
    if (shouldUpdateTemporalQuery || setUpdate) {
      // Update
      updateTemporalQuery(exploreQuery, hash, timeString);
    } else {
      // Create
      createTemporalQuery(exploreQuery, hash, timeString);
      // Set to update
      return ({ shouldUpdateTemporalQuery: true });
    }
  },
  setChartsLayout: ({ chartsLayout }, newChartsLayout) => ({ chartsLayout: newChartsLayout }),
  // Sets the message that will be passed from the ResetPassword component to the login component
  setResetPasswordMessage: ({ resetPasswordMessage }, message) => ({ resetPasswordMessage: message }),
  setViewItemPopoverVisible: ({ viewItemPopoverVisible }, visible) => ({ viewItemPopoverVisible: visible }),
  setMlsSettings: (_, newMlsSettings) => ({ mlsSettings: newMlsSettings }),
  setSsoSettings: (_, newSsoSettings) => ({ ssoSettings: newSsoSettings }),
  setAnonymousUser: (_, newUserSettings) => ({ user: newUserSettings }),
  setStoreHeaderTitle: (_, newStoreHeaderTitle) => ({ storeHeaderTitle: newStoreHeaderTitle }),
  setSecondaryQueries: ({ secondaryQueries }, secondaryQuery) => ({ secondaryQueries: secondaryQuery }),
  addSecondaryQueries: ({ secondaryQueries }, secondaryQuery) => {
    if (Array.isArray(secondaryQuery)) {
      return ({ secondaryQueries: [...secondaryQueries, ...secondaryQuery] });
    }
    return ({ secondaryQueries: [...secondaryQueries, secondaryQuery] });
  },
  setAmChartsSettingsStore: (_, newSettings) => ({ amChartsSettings: newSettings }),
  setStatisticsChartsTabs: (_, newStatisticsChartsTabs = []) => {
    const remappedChartTabs = newStatisticsChartsTabs.map((nstc) => ({
      uuid: nstc.uuid,
      id: nstc.id,
      chartType: nstc.chartType,
    })) || [];
    localStorage.setItem('statistics-chart-tabs', JSON.stringify(remappedChartTabs));
    return ({ statisticsChartsTabs: newStatisticsChartsTabs });
  },
  setDefaultDashboardTabs: (_, newDefaultDashboardTabs = []) => {
    const unique = uniqBy(newDefaultDashboardTabs, 'id');
    const remappedDashboardIds = unique.map((nddi) => ({
      name: nddi.name,
      id: nddi.id,
      dashboardType: nddi.dashboardType,
    })) || [];
    localStorage.setItem('default-dashboard-ids', JSON.stringify(remappedDashboardIds));
    return ({ defaultDashboardTabs: remappedDashboardIds });
  },
  setDefaultMapLocation: (_, newDefaultMapLocation = {}) => ({ defaultMapLocation: newDefaultMapLocation }),
  setUpdateInitialization: (_, newUpdateInitialization) => ({ updateInitialization: newUpdateInitialization }),
  setStatisticsChartRendered: (_, value) => ({ statisticsChartRendered: value }),
  setStatisticsToggleInitial: (_, value) => {
    localStorage.setItem('statistics-view-mode', JSON.stringify(value));
    return ({ statisticsToggleInitial: value });
  },
  setDashboardToggleInitial: (_, value) => {
    localStorage.setItem('dashboard-view-mode', JSON.stringify(value));
    return ({ dashboardToggleInitial: value });
  },
  setGlobalDashboards: (_, dashboards) => ({ globalDashboards: dashboards }),
  setPublicDashboards: (_, dashboards) => ({ publicDashboards: dashboards }),
  setPrivateDashboards: (_, dashboards) => ({ privateDashboards: dashboards }),
  setGlobalCharts: (_, charts) => ({ globalCharts: charts }),
  setPublicCharts: (_, charts) => ({ publicCharts: charts }),
  setPrivateCharts: (_, charts) => ({ privateCharts: charts }),
  setGlobalCubejsMeta: (_, meta) => ({ globalCubejsMeta: meta }),
  // Save View items After Dashboard Query
  updateDashboardViewItemQuery,
  updateDashboardQueryGraphConfig,
  setModifiedViewItems,
  saveTabFilters,
};

export default actions;
