/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'antd/lib/form/util';
import {
  Button, message, Form, Modal, Switch,
} from 'antd';
import { connect } from 'unistore/react';
import { isEmpty } from 'lodash';
import { SettingOutlined } from '@ant-design/icons';

import usePortal from '../../hooks/usePortal';
import ACLSettings from './ACLSettings';
import DisableSecurity from './DisableSecurity';
import { AclWrapperContextProvider } from '../../context/ACLWrapperContext';
import { OAUTH } from '../../constants';
import { AclProviderContext } from '../../context/AclProviderContext';

const ACL_API = `${OAUTH}/api/acl/component/`;

/**
 * @description Fixed the Rules from a Form to be able to create or update
 * @param {Object} values - Values from a Form
 * @param {Object} rules - Existing Rules
 * @param {Object} config - Extra parameters needed, like default name,
 *  creator, the type of item, the id of the item, and the user.
 */
export const handleSubmit = async (values, rules = {}, {
  name, creator, type, id, user, aclProvider,
} = {}) => {
  // Remove undefined values from the request
  const sanitizedRequest = Object.entries(values).reduce((acc, [key, value]) => {
    if (!value && key !== 'public') return acc;

    if (Array.isArray(value) && value[0] === 'none') {
      acc[key] = {
        none: true,
      };

      return acc;
    }
    switch (key) {
      case 'public': {
        // console.log('Private', value, 'Public', !value);
        acc.only_me = !value;
        break;
      }
      case 'firms': {
        acc.firms = value.reduce((offices, officeId) => {
          offices[officeId.toString()] = { officeKey: officeId };
          return offices;
        }, {});
        break;
      }
      case 'members': {
        acc.members = value.reduce((members, agentId) => {
          members[agentId.toString()] = { agent_id: agentId };
          return members;
        }, {});
        break;
      }
      case 'originating_system_id': {
        acc.originating_system_id = value.reduce((mls, mlesId) => {
          mls[mlesId.toString()] = {
            originating_system_id: mlesId,
          };
          return mls;
        }, {});
        break;
      }
      case 'level': {
        acc.level = value.reduce((levels, level) => {
          levels[level.toString()] = {
            level,
          };
          return levels;
        }, {});
        break;
      }
      case 'offices': {
        acc.offices = value.reduce((offices, officeId) => {
          offices[officeId.toString()] = { officeKey: officeId };
          return offices;
        }, {});
        break;
      }
      case 'name': {
        acc.name = value;
        break;
      }
      default: break;
    }

    return acc;
  }, {});

  if (!sanitizedRequest.name) {
    // If a Names wasn't written by default is the name of the component
    sanitizedRequest.name = name;
  }
  if (isEmpty(rules?.creator) && isEmpty(sanitizedRequest?.creator)) {
    // Adds the Creator
    if (!isEmpty(creator)) sanitizedRequest.creator = creator;
    if (isEmpty(creator)) {
      sanitizedRequest.creator = {
        agent_id: user?.agent_id,
        user_id: user?.user_id,
      };
    }
  }
  // console.log('Request', sanitizedRequest);
  if (rules?.id) {
    return fetch(`${ACL_API}${rules.id}/`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(sanitizedRequest),
    }).then((res) => res.json())
      .then((data) => {
        message.success('Rules updated succesfully');
        // console.log('Data ', data);
        // eslint-disable-next-line no-unused-expressions
        aclProvider?.saveRule(data);
        return data;
      })
      .catch((err) => {
        message.error('There was an error updating the rules');
      });
  }
  if (!rules?.id) {
    // console.log('Request Body', sanitizedRequest);

    return fetch(`${ACL_API}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        item_type: type,
        item_id: id,
        ...sanitizedRequest,
      }),
    }).then((res) => res.json())
      .then((data) => {
        message.success('Rules created succesfully');
        aclProvider?.saveRule(data);

        return data;
      })
      .catch((err) => {
        message.error('There was an error creating the rules');
      });
  }
  return 'Nothing was done';
};

/**
 * @description Checks the Rules to see if it should render or not
 * @param {*} ruleSet  - The Rules of the Component
 * @param {Object} user  - Who is the User
 * @param {object} options - Contains functions to set if the component is enable, if
 * the user is the owner, and the name of the rule for debugging
 */
export const checkRules = (ruleSet, user, { setEnable, name = '', setOwner } = {}) => {
  const currentUser = user.access;
  const fixedRules = Array.isArray(ruleSet) ? ruleSet[0] : ruleSet;
  // console.log(`Rules for ${name} `, fixedRules);
  const CHECK_ALL_OR_NONE = RegExp('all|none');
  let enable = true;
  // Add the Disable
  // console.log('RULES', fixedRules);
  if (!fixedRules?.active && fixedRules?.active !== undefined) {
    // Set to Disabled
    // console.log('ENABLE FALSE');
    enable = false;
  }
  // Render if there is no Rule, or the Rule is is empty or the user is the creator
  const isMe = fixedRules?.creator?.agent_id ? (fixedRules?.creator?.agent_id === currentUser?.agent_id)
    : fixedRules?.creator?.user_id === currentUser?.user_id;
  // console.log('Is me ', isMe, ' for ', fixedRules?.name);
  // console.log('There are riles', !fixedRules);
  // console.log('not empty riles', isEmpty(fixedRules));
  // console.log('Composed', (isEmpty(fixedRules?.creator) && fixedRules?.only_me));
  // console.log('Ther is creator', isEmpty(fixedRules?.creator));
  // console.log('Ther is only_me', fixedRules?.only_me);

  if (!fixedRules
      || isEmpty(fixedRules)
    || (isEmpty(fixedRules?.creator) && fixedRules?.only_me)
    || isMe) {
    // Test Only
    // console.log('Is in only me');
    // setEnabled(enable);
    return true;
  }
  // console.log('Private', fixedRules.only_me);
  // console.log('Creator, ', fixedRules.creator);
  // console.log('user', currentUser);
  const creatorAgentId = fixedRules?.creator?.agent_id;
  const creatorUserId = fixedRules?.creator?.user_id;
  // If it Private and it's not the creator return false to avoid rendering
  const isOwner = (creatorAgentId && creatorAgentId === currentUser?.agent_id)
    || (creatorUserId && creatorUserId === currentUser?.user_id) || currentUser?.mmsc >= 8;

  // console.log('Is Owner', isOwner, ' For ', fixedRules?.name);
  if (setOwner) setOwner(isOwner || !fixedRules?.creator);

  if (fixedRules.only_me && !isOwner) {
    return false;
  }
  // console.log('Level ', fixedRules.level);

  // Only check if there is a rule
  if (fixedRules.level || !isEmpty(fixedRules.level)) {
    // Get the Levels as Numbers
    const levels = Object.keys(fixedRules.level).map((levelKey) => parseInt(levelKey, 10));
    // Get Minimun Required Level
    const minLevelRequired = Math.min(...levels);
    // If the user doesn't have it return false
    // console.log('Current', currentUser.mmsc, 'Required', minLevelRequired);
    const meetsMinimunLevel = (currentUser.mmsc || 0) >= minLevelRequired;
    // console.log('Meet Minimun', meetsMinimunLevel);
    if (!meetsMinimunLevel) {
      return false;
    }
    // console.log('No es el if', fixedRules.level[currentUser.mmsc.toString()]);

    const isInLevels = fixedRules.level[currentUser.mmsc.toString()];

    // console.log('Is in LEVLES', isInLevels);
    if (isInLevels) {
      // console.log('ENABLE TRUE');
      enable = !enable ? !!isInLevels.enable : enable;
    } else if (fixedRules.level.all) {
      // console.log('All');
      enable = !enable ? fixedRules.level.all.enable : enable;
      // If it there not a ALL OR NONE and Doesn't meet criteria return false
    } else if (Object.keys(fixedRules.level).some((key) => !CHECK_ALL_OR_NONE.test(key)) && !meetsMinimunLevel) {
      // console.log('Check for none');
      return false;
    }
  }

  // console.log('MLS ', fixedRules.originating_system_id);
  // Check if there is a rule for originating system name
  if (fixedRules.originating_system_id || !isEmpty(fixedRules.originating_system_id)) {
    const userMls = currentUser.mls_name;
    // If it's not inthere don't render
    const isInMLS = fixedRules.originating_system_id[userMls];
    if (isInMLS) {
      enable = !enable ? !!isInMLS.enable : enable;
    } else if (fixedRules.originating_system_id.all) {
      enable = !enable ? fixedRules.originating_system_id.all.enable : enable;
    } else if (Object.keys(fixedRules.originating_system_id).some((key) => !CHECK_ALL_OR_NONE.test(key))) {
      return false;
    }
  }

  // console.log('Office ', fixedRules.offices);
  // Check if there is a rule for Office
  if (fixedRules.offices || !isEmpty(fixedRules.offices)) {
    const userOffice = currentUser.office_agent_id?.toString();
    // if the user office is not in there don't render
    const isInOffice = fixedRules.offices[userOffice];
    if (isInOffice) {
      // console.log('ENABLE TRUE');

      enable = !enable ? !!isInOffice.enable : enable;
    } else if (fixedRules.offices.all) {
      enable = !enable ? fixedRules.offices.all.enable : enable;
    } else if (Object.keys(fixedRules.offices).some((key) => !CHECK_ALL_OR_NONE.test(key))) {
      return false;
    }
  }
  // console.log('Firms ', fixedRules.firms);

  // Same thing as above
  if (fixedRules.firms || !isEmpty(fixedRules.firms)) {
    const userFirm = currentUser.main_office_id?.toString();
    // If the Firms isnt in the Firms Map return false
    const isInFirm = fixedRules.firms[userFirm];
    if (isInFirm) {
      // console.log('ENABLE TRUE');

      enable = !enable ? !!isInFirm.enable : enable;
    } else if (fixedRules.firms.all) {
      enable = !enable ? fixedRules.firms.all.enable : enable;
    } else if (Object.keys(fixedRules.firms).some((key) => !CHECK_ALL_OR_NONE.test(key))) {
      return false;
    }
  }

  // Same thing as above
  // console.log('In members ', fixedRules.members);
  if (fixedRules.members || !isEmpty(fixedRules.members)) {
    const userMember = currentUser.agent_id?.toString();
    const isInMembers = fixedRules.members[userMember];
    if (isInMembers) {
      // console.log('ENABLE TRUE');

      enable = !enable ? !!isInMembers.enable : enable;
      // console.log('ENABLE MEMBER', enable);
    } else if (fixedRules.members.all) {
      enable = !enable ? fixedRules.members.all.enable : enable;
    } else if (Object.keys(fixedRules.members).some((key) => !CHECK_ALL_OR_NONE.test(key))) {
      // console.log('It shoudl Entered here in', name);
      return false;
    }
  }
  // It meets al criteria so please render
  // console.log('Setting Enable', enable);
  if (setEnable) setEnable(enable);
  return true;
};

//  Fixes the names of the fields when receiving the rules
export const mapRulesToForm = (ruleSet) => Object.entries(ruleSet || {}).map(([keyName, value]) => {
  if (RegExp('members|offices|level|firms|originating_system_id').test(keyName) && value) {
    const correctedValues = Object.keys(value);

    return ({ name: keyName, value: correctedValues });
  }
  if (keyName === 'only_me') {
    return ({ name: 'public', value: !value });
  }
  return ({ name: keyName, value });
});

/**
 *
 * @param {*} can What the User can do
 * @param {*} cannot what they user can not do
 * @param {*} subject Over what is the Rule going to get Applied
 * @param {JSX.Element } children components
 * @param {string} itemType Represent what type of item is, ex: ViewItem
 * @param {number } itemId is the Id of the component being wrapper
 */
const ACLWrapper = ({
  // eslint-disable-next-line react/prop-types
  children, user,
  component, // Rules from Server
  name, // name of the Rule / Component
  itemType, // What Type of Item is Views, ViewItems, Etc...
  itemId, // The Id
  itemCreator, // Who's the Item Creator
  withSettings, // Should it Display the Settings
  notEnoughPermission, // JSX for Custom Message on not enough permissions,
  key,
  className,
  ...props
}) => {
  // console.log('Other Props', otherProps);
  // eslint-disable-next-line no-unused-vars
  // const ability = useContext(AbilityContext);
  // Default Rules
  const [rules, setRules] = useState(component || {});
  const aclProvider = useContext(AclProviderContext);
  // Form for ACL Settings
  // THIS FORM IS GIVING ERROR IN CONSOLE BECAUSE IT IS PASSED THROUGH A CONTEXT
  // Form for Enabling a Component
  const [enableForm] = useForm();
  // Is a Disabled Component
  const [isEnabled, setEnabled] = useState(true);
  // Is Owner of Component
  const [isOwner, setOwner] = useState(true);
  // Show or Hide
  const [itShouldRender, setItShouldRender] = useState(true);
  // Handles the Modal of ACl Settings
  const [fields, setFields] = useState();
  const [show, openModal, onOk, onCancel] = usePortal({
    OnOkCallback: () => {
      // console.log('Form Name in Submit', form.getFieldValue('name'));
      // console.log('Form Values', form.getFieldsValue());

    },
  });
  // Handles the modal of the enable form
  const [showEnable, openEnableModal, onOkForEnable, onCancelForEnable] = usePortal({
    OnOkCallback: () => enableForm.submit(),
  });
  // Is it going to Enable for all Public
  const [enableForPublic, setEnableForPublic] = useState(false);

  const loadRules = async () => new Promise((resolve) => {
    const loadComponentRules = async () => {
      // Encode name to request the rule
      // const encodedName = encodeURIComponent(name);
      // // console.log('Encoded Name ', encodedName);
      // const data = await fetch(`${ACL_API}?name=${encodedName}`)
      //   .then((res) => res.json())
      //   .catch(console.log);
      const data = await aclProvider.getRule(name);
      // console.log('data for ACL', name, data);
      if (!isEmpty(data)) {
        resolve(data);
      }
    };
    // If there are no rules, load from database
    if (isEmpty(rules) && isEmpty(component)) {
      loadComponentRules();
    } else if (!isEmpty(component)) {
      resolve(component);
    }
  });

  useEffect(() => {
    let isMounted = true; // note mutable flag
    loadRules().then((data) => {
      if (isMounted) {
        if (data) setRules(data);
      }
    });
    return () => { isMounted = false; }; // cleanup toggles value, if unmounted
  }, [name]);

  const reviewCheckedRules = async () => new Promise((resolve) => {
    if (!isEmpty(rules)) {
      //  Maps the Rules
      const correctedRules = Array.isArray(rules) ? rules[0] : rules;
      const shouldItRenderer = checkRules(rules, user, { setEnable: setEnabled, setOwner });
      setItShouldRender(shouldItRenderer);
      const mappingFields = mapRulesToForm(correctedRules);
      // console.log('Mapped Fields', mappingFields);
      if (!mappingFields.name) {
        mappingFields.name = name;
      }
      // console.log('Mapped Fields', name, mappingFields);

      // Sets the Values in the Forms
      setFields(mappingFields);
      resolve({ shouldItRenderer, mappingFields });
      // console.log('Rules', rules, 'Name', name);
      //  Checks if depending on the rules it should render
      // console.log('Form ', form.getFieldsValue());
    }
    // If there are no rules, it sets by default the name
    if (isEmpty(rules)) {
      setFields([{ name: 'name', value: name }]);
      resolve({ mappingFields: [{ name: 'name', value: name }] });
    }
  });

  useEffect(() => {
    let isMounted = true; // note mutable flag
    reviewCheckedRules().then(({ shouldItRenderer, mappingFields }) => {
      if (isMounted) {
        if (shouldItRenderer) setItShouldRender(shouldItRenderer);
        if (mappingFields) setFields(mappingFields);
      }
    });
    return () => { isMounted = false; }; // cleanup toggles value, if unmounted
  }, [rules]);
  // console.log('Name ', name, ' Is Disabled', isEnabled);
  // console.log('Rules ', rules);
  const cloneRules = (values) => {
    // Rules from Django may come as Array
    const fixedRules = Array.isArray(rules) ? rules[0] : rules;
    const request = {
      item_type: itemType,
      item_id: itemId,
      name: values.name,
      rule_id: fixedRules.id,
    };
    // console.log('Request', request);
    // const CMS_API = 'http://localhost:8000/api/acl/clone/';
    const CMS_API = `${OAUTH}/api/acl/clone`;
    //  Send Request to Clone
    fetch(CMS_API, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    })
      .then(() => message.success('Cloned Succesfully'))
      .catch(() => message.error('There was an error cloning, please try Again Later'));
  };

  const setPrivateEnable = () => {
    // console.log('Privately Enabled');
    // Get Current User
    const currentUser = user?.access;
    // Body Request
    const bodyRequest = {
      members: {
        [currentUser.agent_id.toString()]: {
          agent_id: currentUser.agent_Id,
          enable: true,
        },
      },
    };
    const fixedRules = Array.isArray(rules) ? rules[0] : rules;
    // Sends Request to Enable only for me
    fetch(`${ACL_API}${fixedRules.id}/`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyRequest),
    }).then((res) => res.json())
      .then((data) => {
        setRules(data);
        message.success('Enabled Feature Successfully');
      })
      .catch((err) => message.error('There was an error enabling the feature'));

    onCancelForEnable();
  };

  const publicEnableSubmit = (values) => {
    const keys = Object.keys(values);
    const request = { };

    // console.log('Publlic', values);
    // Maps the Rules of the the correcet format if they are not none
    for (let index = 0; index < keys.length; index += 1) {
      const formKey = keys[index];
      const results = values[formKey] || [];
      if (!results.includes('none')) {
        switch (formKey) {
          case 'members': {
            // Enables  for Everyone
            if (isEmpty(results) || results[0] === 'all') {
              request.members = {};
              request.members.all = {};
              request.members.all.enable = true;
            } else {
              // Maps only the agent_id of the selected users
              request.members = results.reduce((acc, currentValue) => {
                acc[currentValue] = {
                  agent_id: parseInt(currentValue, 10),
                  enable: true,
                };
                return acc;
              }, {});
            }

            break;
          }

          case 'offices': {
            // Enables  for Everyone
            if (isEmpty(results)) {
              request.offices = {};
              request.offices.all = {};
              request.offices.all.enable = true;
            } else {
              // Maps only the agent_id of the selected offices

              request.offices = results.reduce((acc, currentValue) => {
                acc[currentValue] = {
                  officeKey: parseInt(currentValue, 10),
                  enable: true,
                };
                return acc;
              }, {});
            }

            break;
          }
          case 'firms': {
            // Enables  for Everyone

            if (isEmpty(results)) {
              request.firms = {};
              request.firms.all = {};
              request.firms.all.enable = true;
            } else {
              // Maps only the agent_id of the selected offices

              request.firms = results.reduce((acc, currentValue) => {
                acc[currentValue] = {
                  firmKey: parseInt(currentValue, 10),
                  enable: true,
                };
                return acc;
              }, {});
            }

            break;
          }
          case 'level': {
            // This presents a weid behavior if enable for all so is skiped is nothing is selected
            if (!isEmpty(results)) {
              request.level = results.reduce((acc, currentValue) => {
                acc[currentValue] = {
                  level: parseInt(currentValue, 10),
                  enable: true,
                };
                return acc;
              }, {});
            }

            break;
          }

          case 'originating_system_id': {
            // Enables  for Everyone

            if (isEmpty(results)) {
              request.originating_system_id = {};
              request.originating_system_id.all = {};
              request.originating_system_id.all.enable = true;
            } else {
              // Maps only the agent_id of the selected offices

              request.originating_system_id = results.reduce((acc, currentValue) => {
                acc[currentValue] = {
                  originating_system_id: parseInt(currentValue, 10),
                  enable: true,
                };
                return acc;
              }, {});
            }

            break;
          }
          default: {
            break;
          }
        }
      }
    }

    // console.log('Request', request);

    const fixedRules = Array.isArray(rules) ? rules[0] : rules;
    // Send Request to enable feature
    fetch(`${ACL_API}${fixedRules.id}/`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    }).then((res) => res.json())
      .then((data) => {
        aclProvider.saveRule(data);
        setRules(data);
        message.success('Enabled Feature Successfully');
      })
      .catch((err) => message.error('There was an error enabling the feature'));
  };

  // console.log('Is Enable', isEnabled);
  // Provides a Context for for the ACL Settings in case that is not being handle directly by this components
  // console.log('It Should render', itShouldRender);
  return (
    <AclWrapperContextProvider
      value={{
        user: user?.access,
        fields,
        onOk,
        cloneRules,
        handleSubmit: (values) => handleSubmit(values, Array.isArray(rules) ? rules[0] : rules, {
          name, creator: itemCreator, type: itemType, id: itemId, user: user?.access, aclProvider,
        }),
        itemType,
        isOwner,
        rules,
      }}
    >
      {isOwner && withSettings && !window.location.pathname.includes('/share') && (
      <>
        <div
          key={key}
          id={`${itemType}-${itemId}`}
          className={className}
        >
          {(rules?.creator?.agent_id === user.access.agent_id)
              || (isEmpty(rules?.creator) && user.access.mmsc >= 8) ? (
                <SettingOutlined
                  style={{
                    float: 'right',
                  }}
                  className="settings-layout-acl no-print"
                  onClick={openModal}
                />

            ) : <></>}

          <ACLSettings
            settingsVisible={show}
            setACLSettings={onCancel}
          />
        </div>
      </>
      )}
      {/* If it's doing to renger */}
      {itShouldRender ? (
        <div className={className}>
          {/* If is Disable */}
          <DisableSecurity isDisable={!isEnabled}>
            {/* Allows to create Custom Component that know if the user is the owner or not */}
            { children instanceof Function ? children(isOwner, props) : children}
          </DisableSecurity>
          {/* If it's Disable Show a Button to Enable the Component */}
          {!isEnabled && (
            <Button
              size="small"
              type="primary"
              style={{
                position: 'absolute',
                margin: 'auto',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',

              }}
              onClick={openEnableModal}
            >
              Enable me
            </Button>
          ) }
          {/* Modal For Enabling the Feature */}
          <Modal
            title="Enable Feature"
            visible={showEnable}
            onCancel={onCancelForEnable}
            onOk={onOkForEnable}
            getContainer={`#${itemType}-${itemId}`}
            footer={
                [
                  <Button
                    type="danger"
                    onClick={onCancelForEnable}
                  >
                    Cancel
                  </Button>,
                  <Button
                    type="default"
                    onClick={() => setEnableForPublic(true)}
                  >
                    Public
                  </Button>,
                  <Button
                    type="primary"
                    onClick={setPrivateEnable}
                  >
                    Private
                  </Button>,

                ]
            }
            keyboard={false}
            maskClosable={false}
          >
            {/* Display the Initial Message */}
            {!enableForPublic ? (
              <p>  Would you like to enable this feature? Only for you, or with a group discount(Public)</p>
            ) : (
            /**              // If it chooses to Enable for Everyone */
              <ACLSettings
                user={user?.access}
                settingsVisible={showEnable}
                form={enableForm}
                setACLSettings={onCancelForEnable}
                onOk={onOkForEnable}
                handleSubmit={publicEnableSubmit}
                cloneRules={cloneRules}
                enable
              />
            ) }
          </Modal>
        </div>

      ) : (
        /** We can have Custom Not Enough permission Views  */
        notEnoughPermission || null
      ) }

    </AclWrapperContextProvider>

  );
};

export default connect('user')(ACLWrapper);
