import { Spin } from 'antd';
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'unistore/react';
import { isEmpty } from 'lodash';
import { actions } from '../../store';
import { OAUTH as BASE_URL } from '../../constants';

const MlsSettingsWrapper = ({ children, setMlsSettings, setSsoSettings }) => {
  const [loading, isLoading] = useState(true);
  useEffect(() => {
    const getSettings = async () => {
      // stores the hostname of the applicaiton
      let systemName = window.location.hostname;
      const parts = systemName.split('.');
      [systemName] = parts;
      // if the systemName is localhost or dev-react it means is under MLSDATATOOLS
      if (systemName === 'localhost' || systemName === 'dev-react') {
        systemName = 'MLSDATATOOLS';
      } else {
        // else it means it is a MLS Client so we create the originating_system name in case
        systemName = systemName.toUpperCase();
      }
      // using the systemName we look for the settings based on the originating_system_name (applied a url filter)
      const [foundSettings] = await fetch(`${BASE_URL}/api/master-acl/mls-settings/?originating_system_name=${systemName}`)
        .then((res) => res.json())
        .catch((e) => []);

      if (!isEmpty(foundSettings) && foundSettings.id) setMlsSettings(foundSettings);
      // if there is no user-token
      // stores the url origin of the application
      const appUrl = window.location.host;
      // we request to the service providers table if there is a record with the same appUrl
      fetch(`${BASE_URL}/sso/identity-providers/?appUrl=${appUrl}`)
        .then((res) => res.json())
        .then((data) => {
          // it will always return an array, so if the first in the array has the key id we know is valid data
          if (data[0].id) setSsoSettings(data);
          // set the waiting variable to false (the waiting stops and the normal login will load)
          isLoading(false);
        })
        .catch(() => {
          // set the waiting variable to false (the waiting stops and the normal login will load)
          isLoading(false);
          // message.error('There was an error in the server');
        });
    };
    getSettings();
  }, []);
  if (loading) return <Spin size="large" />;
  return (
    <>
      {children}
    </>
  );
};

export default connect('', actions)(MlsSettingsWrapper);
