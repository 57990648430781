const testImage = async (img) => {
  const np = new Promise((resolve, reject) => {
    fetch(img)
      .then((res) => {
        if (res.ok) {
          resolve(true);
        }
      })
      .catch((err) => reject(err));
  });
  return np;
};

export default testImage;
