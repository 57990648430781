import moment from 'moment';
import { isEmpty } from 'lodash';
import { OAUTH } from '../../constants';
import { getAclDatabase, schemaLoader } from './AclSchema';

const ACL_API = `${OAUTH}/api/acl/component/`;

/**
 * Class that allows the update and read of the acl component for static values
 */
export class AclProvider {
  constructor(db, { fetch } = {}) {
    this.db = db;
    this.fetch = fetch;
  }

  static async init() {
    // Create Database
    const db = getAclDatabase();
    // Add Schema
    await schemaLoader(db);
    // Open Connection
    await db.open();
    return db;
  }

  async getRule(name) {
    if (!name) return {};
    // Get Rules from Database
    if (!this.db) return {};
    const rules = await this.db.rules.get({ name });
    // console.log('Rules Found', rules, name);
    // Check if rules exists of need update
    if (!rules || moment().diff(moment(rules.lastUpdate), 'hours') >= 24) {
      const encodedName = encodeURIComponent(name);
      //   console.log('Encoded Name ', encodedName);
      // Request to get the rules
      const [data] = await this.fetch(`${ACL_API}?name=${encodedName}`)
        .then((res) => res.json())
        .catch(() => [rules || {}]);
      //   console.log('Data', encodedName, data);
      if (!isEmpty(data)) {
        // Save the rule
        this.saveRule(data);
      }
      return data;
    }
    return rules;
  }

  async saveRule(rule) {
    this.db.rules.put({ ...rule, lastUpdate: moment().format() });
  }

  async close() {
    await this.db?.close();
  }
}

export const createACLProvider = async () => {
  const db = await AclProvider.init();
  return new AclProvider(db, {
    fetch: window.fetch.bind(window),
  });
};

export default createACLProvider;
