import { Dexie } from 'dexie';

export const schemaLoader = async (db) => {
  await db.version(1).stores({
    rules: 'id,name,creator,originating_system_id,level,offices,firms,teams,members,only_me,active,type,url,default_plan,trigger_callback,callback_type,callback_param,google_scope,parent_id,lastUpdate',
  });

  return db;
};

export const getAclDatabase = () => new Dexie('acl');
