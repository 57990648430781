/**
 * @CUBE-1287 - This Files Creates a Hook for a Simple Local Database
 */

import { useEffect, useState } from 'react';
import { Dexie } from 'dexie';

const schemaLoader = async (db) => {
  await db.version(1).stores({
    dashboardContent: '++id,view,viewItems,updatedDate',
  });

  return db;
};

const useSimpleDb = (name) => {
  const [db, setDb] = useState();

  const createDb = async (databaseName) => {
    const newDb = new Dexie(databaseName);
    await schemaLoader(newDb);
    await newDb.open();
    setDb(newDb);
    return newDb;
  };

  useEffect(() => {
    createDb(name);

    return () => {
      // Avoid A Second Crash on Instanteneus Crashes
      db?.close();
    };
  }, []);

  return [db];
};

export default useSimpleDb;
