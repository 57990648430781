/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'unistore/react';
import logo from './logo.png';
import logoAlt from './logoWhite.png';

const MlsLogo = ({
  mlsSettings, style, alt, className, id,
}) => (
  <>
    <img
      src={mlsSettings.logo || (alt ? logoAlt : logo)}
      alt="mls-logo"
      style={style}
      className={` ${className} media-mls-logo`}
      id={id}
    />
  </>
);

export default connect('mlsSettings')(MlsLogo);
