import { createContext } from 'react';

const FromStatisticsContext = createContext({
  fromStatistics: false,
  publicChartsHandlers: { localCharts: [], setLocalCharts: () => {} },
  selectedViewItem: {},
});

const FromStatisticsProvider = FromStatisticsContext.Provider;

export {
  FromStatisticsContext,
  FromStatisticsProvider,
};
