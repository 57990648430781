/**
 * Context for the ACL Provider
 */
import { createContext } from 'react';
import { AclProvider } from '../functions/ACLDatabase/AclProvider';

const AclProviderContext = createContext(new AclProvider());
const AclProviderContextProvider = AclProviderContext.Provider;

export {
  AclProviderContext,
  AclProviderContextProvider,
};
