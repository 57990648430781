import React from 'react';

export const { REACT_APP_SITE_TITLE } = process.env;
// eslint-disable-next-line no-underscore-dangle
export const __DEV__ = process.env.NODE_ENV === 'development';

export const CMS = 'https://oauth2.mlsdatatools.com/api/cms';
// export const CMS = 'http://localhost:8000/api/cms';
// export const OAUTH = 'http://localhost:8000';
export const OAUTH = 'https://oauth2.mlsdatatools.com';
// export const WEBSOCKET_DB = 'ws://localhost:8000/ws/db-sync';
export const WEBSOCKET_DB = 'wss://oauth2.mlsdatatools.com/ws/db-sync';
export const WS_MESSAGES = {
  UPDATE_DATA: 'update_data',
};
export const LG = 12;
export const SM = 6;
export const XS = 2;
export const BodyBreakpoints = {
  lg: 996, sm: 768, xs: 0,
};
export const RHDB = 20;
export const ROW_HEIGHT = 10;
export const MAPSKEY = 'AIzaSyCxUrDZTHwZcskVRMQ5favL3gSJ111Tkvw';
export const ZOOM = 10;
// WARNING! TOUCHING THIS CONSTANT 'MY' MAY CAUSE THE AUTOCOMPLETES FOR DASHBOARDS AND STATISTICS TO STOP FILTERING !!!
export const MY = (children) => (
  <>
    <b className="no-print">My&nbsp;</b>
    {children}
  </>
);

export const ShareParametersKeys = {
  FILTERS: 'f',
  DATE_RANGE: 'dr',
  CRITERIA: 'c',
  DYNAMIC_TITLES: 'dt',
  ALLOWED_CHARTS_MOD: 'acm',
  MEASURES: 'm',
  DIMENSIONS: 'd',
  ORDER: 'o',
  LIMIT: 'l',
  SEGMENTS: 's',
};

export const CUBEJS_ROW_LIMIT_MAX = 50000;
export default process.env;

export const VIZSTATE_SETTINGS_DEFAULT = {
  tabs: false,
  oneChartPerRow: false,
  oneChartPerMeasure: false,
};

export const APPLY_DASHBOARD_QUERY = {
  START: 'START',
  RUNNING: 'RUNNING',
  FINISHED: 'FINISHED',
};

// Local Measures, tyhey have to follow the same format as CubeJS Schema Measures
export const LOCAL_MEASURES = {
  'LocalMeasures.MarketShareUnits': {
    name: 'LocalMeasures.MarketShareUnits',
    type: 'percent',
    title: 'Transaction Total Office Market Share Units',
    shortTitle: 'Market Share Units',
    meta: {
      dependants: ['TransactionTotalOffice.SoldCountTotal'],
      dependantCube: 'TransactionTotalOffice',
    },

  },
  'LocalMeasures.MarketShareVolume': {
    name: 'LocalMeasures.MarketShareVolume',
    type: 'percent',
    title: 'Transaction Total Office Market Share Volume',
    shortTitle: 'Market Share Volume',
    meta: {
      dependants: ['TransactionTotalOffice.SoldVolumeTotal'],
      dependantCube: 'TransactionTotalOffice',
    },

  },
};
export const defaultTabSearchbarSettings = {
  names: true,
  status: false,
  dateranges: false,
};
