/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { connect } from 'unistore/react';
import { actions } from '../../store';
import testImage from '../../functions/testImage';
import { OAUTH } from '../../constants';
/**
 * Overview: IsAuthenticated component that is a wrapper to know when a user is authenticated
 * has two props the children (what it is wrapping) and the user from the store
 */

const removeUser = (setUserData, history) => {
  // removes the token from local storage
  localStorage.removeItem('user-token');
  // sets user data to be empty
  setUserData({});
  // history push to the login page
  history.push('/login');
};

const IsAuthenticated = ({
  user, setUserData, /* setUserAccessToken, */children,
  userAvatar, setUserAvatar,
}) => {
  // stores the information to know if it is authenticated
  const [isUserAuthenticated, setUserAutenticated] = useState(localStorage.getItem('user-token'));
  // stores the expiration of the refresh token
  const [refreshExp, setRefreshExp] = useState();
  // stores the expiration of the access token
  const [accessExp, setAccessExp] = useState();
  // stores todays date (actually this exact moment)
  const today = new Date();
  // for the use of history hook
  const history = useHistory();

  // gets triggered when loading for the first time
  useEffect(() => {
    // if the item exists
    if (isUserAuthenticated) {
      // sets the authenticated user variable to be the item
      setUserAutenticated(JSON.parse(isUserAuthenticated));
      // sets the authenticated user in the store
      setUserData(JSON.parse(isUserAuthenticated));
    }
  }, []);

  // gets triggered whenever the user changes
  useEffect(() => {
    // if the user is empty and there is no token in local storage
    if (isEmpty(user) && !localStorage.getItem('user-token')) {
      // sets the variable to empty
      setUserAutenticated();
    }
    if (!isEmpty(user) && user.refresh && isUserAuthenticated) {
      // setting the expiration dates of the refresh token and the access token
      setRefreshExp(new Date(user.refresh.exp * 1000));
      setAccessExp(new Date(user.access.exp * 1000));
    }
    testImage(userAvatar)
      .catch(() => setUserAvatar(null));
  }, [user]);

  // if the refresh expiration is less than today it is already expired
  // and if the user is not empty
  // This could be the culprit
  if (refreshExp < today && !isEmpty(user)) {
    removeUser(setUserData, history);
  }

  // if the access expiration is less than today and isUserAuthenticated exists
  if (accessExp < today && !isEmpty(isUserAuthenticated) && !isEmpty(isUserAuthenticated.refresh)) {
    // fetches the new access token using the refresh token
    fetch(`${OAUTH}/api/token/refresh/`, {
    // fetch('http://localhost:8000/api/token/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        refresh: typeof isUserAuthenticated.refresh === 'string'
          ? isUserAuthenticated.refresh : isUserAuthenticated.refresh?.refreshToken,
      }),
    }).then((res) => res.json())
      .then((res) => {
        // if the res has the access prop
        if (res.access) {
          // action from the store to set the data of the user
          setUserData(res);
          // setUserAccessToken
        }
      })
      .catch((e) => console.log(e));
    // history.push('/');
  }

  /* if (isUserAuthenticated && isEmpty(isUserAuthenticated.refresh)) {
    removeUser(setUserData, history);
  } */
  // Added Logic to go directly into print even if user is not logged in
  return (
    <div id="is-authenticated">
      {!isEmpty(isUserAuthenticated) || !isEmpty(user) || window.location.pathname.includes('/print') ? children : (
        !window.location.pathname.includes('/share/')
        && !window.location.pathname.includes('/login/')
        && !window.location.pathname.includes('/register')
        && !window.location.pathname.includes('/sso')
        && <Redirect to="/login" />
      )}
    </div>
  );
};

export default connect(['user', 'userAvatar'], actions)(IsAuthenticated);
