/* eslint-disable react/prop-types */
import {} from 'dotenv/config';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import {
  BrowserRouter as Router, Route, Redirect, withRouter,
} from 'react-router-dom';
import { Spin, Layout } from 'antd';
import Header from './components/Header';
import App from './App';
import * as serviceWorker from './serviceWorker';
import IsAuthenticated from './components/Authentication/IsAuthenticated';
import ErrorBoundry from './components/ErrorBoundry/ErrorBoundry';
import MlsSettingsWrapper from './components/Settings/MlsSettingsWrapper';

// import ExplorePage from "./pages/ExplorePage";
// import DashboardPage from "./pages/DashboardPage";

// import ETLPage from "./pages/ETLPage";

const ExplorePage = React.lazy(() => import('./pages/ExplorePage'));
const DashboardPage = React.lazy(() => import('./pages/DashboardPage'));
const ETLPage = React.lazy(() => import('./pages/ETLPage'));
const StatisticsPage = React.lazy(() => import('./pages/StatisticsPage'));
// const TestPage = React.lazy(() => import('./pages/Tests'));
const SettingsPage = React.lazy(() => import('./pages/SettingsPage'));
const SharedViewPage = React.lazy(() => import('./pages/SharedViewPage'));
const LoginPage = React.lazy(() => import('./pages/LoginPage'));
const Register = React.lazy(() => import('./components/Authentication/Register'));
const ResetPassword = React.lazy(() => import('./components/ResetPassword/ResetPassword'));
const ChartEditorPage = React.lazy(() => import('./pages/ChartEditorPage'));
const SSOParagon = React.lazy(() => import('./components/SSO/SSOParagon'));
const SSOClareity = React.lazy(() => import('./components/SSO/SSOClareity'));
const HomePage = React.lazy(() => import('./pages/HomePage'));
const PrintPage = React.lazy(() => import('./pages/PrintPage'));

const AppLayout = withRouter(({ location, children }) => (
  <Layout style={{ height: '100%' }}>
    <Header location={location} />
    <Layout.Content>{children}</Layout.Content>
  </Layout>
));

ReactDOM.render(
  <Suspense fallback={<Spin size="large" className="Spinner" tip="Loading..." />}>
    <App>
      <MlsSettingsWrapper>
        <Router>
          <ErrorBoundry>
            <IsAuthenticated>
              {/* Pass from URL and to URL for the Redirect component */}
              <Route exact path="/dashboard">
                <Redirect
                  from="/dashboard"
                  to={{
                    pathname: '/dashboard/public',
                    search: '?page=1',
                  }}
                />
              </Route>
              <Route exact path="/charts">
                <Redirect
                  from="/charts"
                  to={{
                    pathname: '/charts/public',
                    search: '?page=1',
                  }}
                />
              </Route>
              <AppLayout>
                <Route key="index" exact path="/" component={HomePage} />
                <Route key="dashboard" exact path="/dashboard/:view?/:update?" component={DashboardPage} />
                <Route key="explore" path="/explore/:userId?/:hashCode?" component={ExplorePage} />
                <Route key="import" path="/etl" component={ETLPage} />
                <Route
                  key="simple-editor"
                  exact
                  path="/charts/:viewItemId/chart/update/:userId?/:hashCode?"
                  component={ChartEditorPage}
                />
                <Route key="statistics" exact path="/charts/:tab/:update?" component={StatisticsPage} />
                {/* <Route key="test" path="/test" component={TestPage} /> */}
                <Route key="settings" path="/settings" component={SettingsPage} />
              </AppLayout>
              <Route key="print" path="/print/:type/:identifier" component={PrintPage} />
            </IsAuthenticated>
            <Route key="register" path="/register/:userId?/:registerHash?" component={Register} />
            <Route key="login" exact path="/login/:userId?/:passwordHash?" component={LoginPage} />
            <Route key="share" path="/share/:type/:memberName?/:identifier" component={SharedViewPage} />
            <Route key="reset-password" path="/reset-password" component={ResetPassword} />
            <Route key="sso-paragon" exact path="/sso/paragon" component={SSOParagon} />
            <Route key="sso-clareity" exact path="/sso/clareity/:mlsClient?" component={SSOClareity} />
          </ErrorBoundry>
        </Router>
      </MlsSettingsWrapper>
    </App>
  </Suspense>,

  document.getElementById('root'),
); // If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
