import { useState } from 'react';

// Hook to handle the use of a Modal/Portal
const usePortal = ({ OnOkCallback, onOkPromise: OnOkPromise } = {}) => {
  // Hide or Show a Modal/Portal
  const [show, setShow] = useState(false);

  // Handle Cancel the use
  const onCancel = () => setShow(false);
  // Handle the Click Ok button
  const onOk = (...values) => {
    if (OnOkCallback) {
      OnOkCallback(...values);
      setShow(false);
    } else if (OnOkPromise) {
      OnOkPromise(...values)
        .then(() => setShow(false))
        .catch(() => undefined);
    } else {
      setShow(false);
    }
  };
  // to open the modal
  const openModal = () => setShow(true);

  // Return the Value of the hook
  return [show, openModal, onOk, onCancel];
};

export default usePortal;
