/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import html2canvas from 'html2canvas';
import { Alert } from 'antd';
import { OAUTH } from '../../constants';

const API = `${OAUTH}/api/cms/crash-report/`;

class ErrorBoundry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    // console.log('Error');
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const errorLogs = {
      url: window.location.href,
      error: error.toString(),
      errorInfo,
    };
    // console.log(errorLogs);
    // eslint-disable-next-line no-unused-vars
    const file = new Blob([JSON.stringify(errorLogs)], { type: 'application/json' });

    /* Verify that the Logs Were Created  */
    // const fileURL = URL.createObjectURL(file);

    // const linkElement = document.createElement('a');

    // // add the file url
    // linkElement.setAttribute('href', fileURL);
    // linkElement.download = 'Error.json';
    // // add it to the DOM
    // document.body.appendChild(linkElement);
    // linkElement.click();
    // Create a IMG of the Screent
    const root = document.querySelector('body');
    html2canvas(root)
      .then((canvas) => {
        //  This is is the BASE 64 Img
        // eslint-disable-next-line no-unused-vars
        canvas.toBlob((img) => {
          const data = new FormData();
          data.append('screenshot', img);
          data.append('logs', file);

          fetch(API, {
            method: 'POST',
            body: data,
          });
        });
      });

    setTimeout(() => {
      // const { history } = this.props;
      // history.push('/explore');
      const getUrl = window.location;
      if (process.env.NODE_ENV === 'development') {
        window.location.reload();
      } else {
        window.location.assign(`https://${getUrl.host}.com`);
      }
    }, 3000);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    // console.log('State', this.state);
    if (hasError) {
      return (
        <Alert
          message=" There was an error, restarting App..."
          type="error"
          showIcon
        />
      );
    }

    // console.log('Erro Boundry', children);
    return (
      <>
        {children}
      </>
    );
  }
}

export default withRouter(ErrorBoundry);
