/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Dropdown } from 'antd';

/**
 * @description Is a generic Component for Buttons that will have some behaviour
 * but will also contain a dropdown, this is mostly used for buttons related to the ACL
 * It accepts the same Props as the Dropdown.Button, https://ant.design/components/dropdown/#Dropdown.Button
 * @param {Object} props
 */

const GenericDropdownButton = ({
  icon,
  onClick,
  menu,
  children,
  ...props
}) => {
  const [visibleDropdown, setVisibility] = useState(false);

  return (
    <Dropdown.Button
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onClick={onClick}
      icon={icon}
      overlay={menu}
      visible={visibleDropdown}
      onVisibleChange={(flag) => {
        setVisibility(flag);
      }}
    >
      {children}
    </Dropdown.Button>
  );
};

export default GenericDropdownButton;
