/**
 * Overview: This file is to be able to pass in the required values from the ACL Wrapper to the ACL Settings
 * without having to be a Direct Child of it
 *
 */
import { createContext } from 'react';

// Context for ACL Settings things required:
/**
 * The User Access Values
 * the Form that will contain the Rules
 * What to Do on Ok
 * What to do on Clone
 * and how it will handle the submit
 */

const AclWrapperContext = createContext({
  user: {},
  fields: undefined,
  itemType: '',
  onOk: () => {},
  cloneRules: () => {},
  handleSubmit: () => {},
  isOwner: true,
  rules: {},
});

const AclWrapperContextProvider = AclWrapperContext.Provider;

export default AclWrapperContext;

export {
  AclWrapperContext,
  AclWrapperContextProvider,
};
