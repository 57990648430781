/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { withRouter } from 'react-router';
import { Layout, Menu } from 'antd';
import { SettingOutlined } from '@ant-design/icons';

import styled from 'styled-components';
// import { SignOut } from 'aws-amplify-react';
import { connect } from 'unistore/react';
import { actions } from '../store';
import ViewItemMedia from './ViewItems/ViewItemMedia';
import Logout from './Authentication/Logout';
import MlsLogo from './MlsLogo';
// import GenericDropdownButton from './GenericDropdownButton';
// import ACLWrapper from './ACL/ACLWrapper';
// import ACLSettings from './ACL/ACLSettings';
import ACLButtonWrapper from './ACL/ACLButton';

const StyledHeader = styled(Layout.Header)`
  padding: 0 28px;
`;

const StyledMenu = styled(Menu)`
  background: transparent;
  line-height: 41px;
  &&.ant-menu-horizontal {
    white-space: nowrap;
    border: 0;
    box-shadow: none;
  }
`;

const MenuItemStyled = styled(Menu.Item)`
  && {
    top: 4px;
    border-bottom: 4px solid transparent;

    &:hover {
      border-bottom: 4px solid transparent;
      & > a {
        transition: 500ms;
        color: #ffffff;
        opacity: 1;
      }
    }
  }
  &&.ant-menu-item-selected
  {
    color: white;
    border-bottom: 4px solid white;

    & > a {
      opacity: 1;
    }
  }
  && > a {
    color: #ffffff;
    opacity: 0.60;
    font-weight: bold;
    letter-spacing: 0.01em;
  }
`;
const ACLButtonWrapperStyled = ACLButtonWrapper;
/* const ACLButtonWrapperStyled = styled(ACLButtonWrapper)`
&& {
    top: 4px;
    border-bottom: 4px solid transparent;
    fill: currentColor;
    fill-opacity: 0.6;

    &:hover {
      border-bottom: 4px solid transparent;
      fill: currentColor;
      fill-opacity: 1;
      & > a {
        color: #ffffff;
        opacity: 1;
      }
    }
  }
  &&.ant-menu-item-selected
  {
    color: white;
    border-bottom: 4px solid white;

    & > a {
      opacity: 1;
    }
  }
  && > a {
    color: #ffffff;
    opacity: 0.60;
    font-weight: bold;
    letter-spacing: 0.01em;
  }
`; */

const Logo = styled.div`
  float: left;
  margin-right 40px;
`;

const signOutStyles = {
  color: 'white',
  background: 'none',
  textTransform: 'none',
  fontSize: '13px',
  fontWeight: 'bold',
  minWidth: 0,
  border: 'none',
};

const Header = ({
  location, selectView = () => { }, mlsSettings, setSelectedViewItem, setStoreHeaderTitle,
  resetMixedCharts, resetChartType,
}) => (
  <>
    {(!location.pathname.startsWith('/share') && !location.pathname.startsWith('/print'))
    && (
    <StyledHeader
      className="no-print"
      style={{
        background: mlsSettings.system_colors?.themePrimaryColor,
        minHeight: 76,
      }}
    >
      <StyledMenu
        mode="horizontal"
        // selectedKeys={[`/${location.pathname.split('/')[1]}`]}
        // selectedKeys={[location.pathname]}
        selectedKeys={[location.pathname.split(/(?=\/)/g)[0]]}
      >
        <MenuItemStyled key="/" onClick={() => selectView(-1)}>
          <Link onClick={() => setSelectedViewItem({})} to="/">
            <Logo>
              <ACLButtonWrapperStyled
                className="wrapper-style-header"
                name="home-link"
                link
                key="home-link"
                /* icon={<SettingOutlined className="wheelcog-tabs-parent" />} */
                button={(
                  <span>
                    <MlsLogo
                      style={{ maxWidth: '100px', height: 'auto' }}
                      alt
                    />
                  </span>
                )}
              />
            </Logo>
          </Link>
        </MenuItemStyled>

        <MenuItemStyled key="/dashboard" onClick={() => selectView(-1)}>
          <Link onClick={() => setSelectedViewItem({})} to="/dashboard">
            <ACLButtonWrapperStyled
              className="wrapper-style-header"
              name="dashboard-link"
              link
              key="dashboard-link"
              icon={<SettingOutlined className="wheelcog-tabs-parent" />}
              button={<span>Dashboard&nbsp;</span>}
            />
          </Link>
        </MenuItemStyled>

        <MenuItemStyled key="/charts">
          <Link to="/charts">
            <ACLButtonWrapperStyled
              className="wrapper-style-header"
              name="statistics-link"
              link
              key="statistics-link"
              icon={<SettingOutlined className="wheelcog-tabs-parent" />}
              button={<span>Charts&nbsp;</span>}
            />
          </Link>
        </MenuItemStyled>
        <MenuItemStyled key="/explore">
          {/* Applies the ACl to the Link Component */}
          <Link
            onClick={() => {
              setSelectedViewItem({});
              setStoreHeaderTitle({});
              resetChartType();
              resetMixedCharts();
            }}
            to="/explore"
          >
            <ACLButtonWrapperStyled
              className="wrapper-style-header"
              name="explore-button"
              link
              key="explore-link"
              icon={<SettingOutlined className="wheelcog-tabs-parent" />}
              button={<span>Explore&nbsp;</span>}
            />
          </Link>
        </MenuItemStyled>
        <MenuItemStyled key="/etl">
          <Link to="/etl">
            <ACLButtonWrapperStyled
              className="wrapper-style-header"
              name="etl-link"
              link
              key="etl-link"
              icon={<SettingOutlined className="wheelcog-tabs-parent" />}
              button={<span>ETL&nbsp;</span>}
            />
          </Link>
        </MenuItemStyled>
        <MenuItemStyled style={{ float: 'right', paddingRight: 0, ...signOutStyles }} key="log-out">
          <Logout />
        </MenuItemStyled>
        <MenuItemStyled key="/settings" style={{ float: 'right' }}>
          <Link to="/settings/profile-settings">Settings&nbsp;</Link>
        </MenuItemStyled>
        <MenuItemStyled key="avatar" style={{ float: 'right', paddingRight: 0, width: '4.7em' }}>
          <ViewItemMedia enabled={false} mediaType="Media-Member-Photo" />
        </MenuItemStyled>
      </StyledMenu>
    </StyledHeader>
    )}
  </>
);

export default connect(['mlsSettings'], actions)(withRouter(Header));
