/* eslint-disable react/prop-types */
import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import removeButtonSvg from './remove-button.svg';

const StyledButton = styled.a`
  height: 16px;
  width: 16px;
  background-image: url(${removeButtonSvg});
  display: block;
  position: fixed;
  top: -5px;
  z-index: 9;

  &:hover {
    background-position: 16px 0;
    display: block;
  }
`;

const RemoveButtonGroup = ({
  onRemoveClick, children, display, forcePermanent, disabled, ...props
}) => (
  forcePermanent ? (<>{children}</>) : (
    <>
      {display ? (
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        <Button.Group {...props}>
          {children}
          <StyledButton onClick={onRemoveClick} disabled={disabled} />
        </Button.Group>
      ) : (<>{children}</>)}
    </>
  )
);

export default RemoveButtonGroup;
