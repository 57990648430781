/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Upload, message, Button, Tooltip,
} from 'antd';
import { /* LoadingOutlined, PlusOutlined, */DeleteOutlined, UserOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from 'unistore/react';
import { isEqual, isEmpty } from 'lodash';
import RemoveButtonGroup from '../QueryBuilder/RemoveButtonGroup';
import { actions } from '../../store';
import MlsLogo from '../MlsLogo';
import { CMS, OAUTH as BASEURL } from '../../constants';
/**
 * Overview: Component to have the media of the views be loaded and rendered
 * Right now is a plain component that is not linked to the cms
 */

// function to handle that is a file base64
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

// function to handle the validations before upload of the avatar
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

// the MAIN COMPONENT you pass it a mediaType, an item (which is the view item it belongs,
// if you want to create a new one, you don't pass it anything)
// and the enabled prop to tell it if it it is enabled or disabled
// (to be able to upload or just see the media)

/* It has a mediaType you set it, an item in case you update an existent record,
enabled boolean to know if it can be uploaded or not, the removeItem function (specific only to ViewTemplate),
The items for the generic items in ViewTemplate, a fakeId to be able to delete the generic item,
Then the last 3 props ar from the store to update the view items and the current view
Added new prop for the store userAvatar it is basically the data we need to get an avatar from storage */

const ViewItemMedia = ({
  mediaType, item, enabled, removeItem,
  items, addItems, fakeId,
  updateViewItems, selectedView, updateSelectedView,
  userAvatar, user, className, parentType = 'view', parentContainer,
  updateItems, imgClassName, id,
}) => {
  const [fullPath, isPath] = useState();
  const [remove, toRemove] = useState(false);
  // stores the path of the image
  const [imagePath, setImagePath] = useState();
  // stores the url of the image
  const [imageUrl, setImageUrl] = useState();
  // stores the loading state of the component
  // const [loading, setLoading] = useState(false);
  // this is the uploadButton when there is no image
  /* const uploadButton = (
    <span>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <span className="ant-upload-text">Upload</span>
    </span>
  ); */

  // function to handle the change of the component that means if something gets uploaded
  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      // setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imgUrl) => {
        // sets the loading spinner to false
        // setLoading(false);
        // sets the iamge url based on the callback
        setImageUrl(imgUrl);
        // sets the image path based on the response where it saved the file
        setImagePath(info.file.response.media.slice(7));
      });
    }
  };

  // function to delete the media
  const deleteMedia = () => {
    // if item and id exist
    if (item && item.id) {
      // the body of the request to empty the settings and the media field
      const formData = new FormData();
      formData.append('media', '');
      formData.append('settings', JSON.stringify({ url: '' }));
      formData.append('parent_item', null);
      fetch(`${CMS}/viewItemMedia/${item.id}/`, {
        method: 'PATCH',
        // headers: { 'Content-Type': 'application/json' },
        body: formData,
      });

      if (removeItem) removeItem(item.id);
    }
  };

  // this gets triggerd when imageUrl changes
  const customPostRequest = (option) => {
    // if it exists a imageUrl and an imagePath
    const {
    // onSuccess, onError, action, onProgress,
      file,
    } = option;
    // this is the body of the fetch request to create the viewItem
    const formData = new FormData();
    formData.append('name', `${user.access.user_id}-${mediaType}`);
    formData.append('type', mediaType);
    // THIS WILL BE REHABILITADED WHEN WE SET THE URL CORRECTLY
    // formData.append('settings', JSON.stringify({ url: imageUrl }));
    formData.append('enabled', true);
    formData.append('creator', JSON.stringify({ user_id: user.access.user_id }));
    formData.append('media', file);
    if (parentType === 'viewitem') {
      // console.log('Parent Container', parentContainer);
      formData.append('parentViewItem', parentContainer.id);
    }
    // if the item prop does not exists and there is no item id it will do POST
    if (!item && fakeId) {
      // fetch()
      const addToViews = async () => {
        let newItem;
        // Get this url from response in real world.
        getBase64(file, async (imgUrl) => {
          // sets the iamge url based on the callback
          // formData.append('settings', JSON.stringify({ base64: imgUrl }));
          setImageUrl(imgUrl);
          setImagePath(`${BASEURL}/media/${file.name}`);
          newItem = await fetch(`${CMS}/viewItemMedia/`, {
            method: 'POST',
            // headers: { 'Content-Type': 'application/json' },
            body: formData,
          }).then((res) => res.json());

          if (parentType === 'view') {
            fetch(`${CMS}/views/${selectedView.id}/`, {
              method: 'PATCH',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                views_items: [...selectedView.views_items, newItem.id],
              }),
            }).then((res) => res.json())
              .then((res) => updateSelectedView(res));
          } else {
            updateItems(newItem);
          }
        });
      };
      // triggers the above function
      addToViews();
      // stores the header items in which the id is not equal to the one in item content
      const newItems = items.filter((el) => el.id !== fakeId);
      addItems(newItems || []);
      // else it will do an update to the current item
    }
  };

  const customPatchRequest = (option) => {
    const {
    // onSuccess, onError, action, onProgress,
      file,
    } = option;
    // this is the body of the fetch request to create the viewItem
    const formData = new FormData();
    formData.append('name', `${user.access.user_id}-${mediaType}`);
    // formData.append('type', mediaType);
    formData.append('media', file);
    // Get this url from response in real world.
    getBase64(file, (imgUrl) => {
      // sets the iamge url based on the callback
      formData.append('settings', JSON.stringify({ base64: imgUrl }));
      setImageUrl(imgUrl);
      setImagePath(`${BASEURL}/media/${file.name}`);
      if (item && item.id && !isEqual(item.media, imagePath)) {
        fetch(`${CMS}/viewItemMedia/${item.id}/`, {
          method: 'PATCH',
          // headers: { 'Content-Type': 'application/json' },
          body: formData,
        }).then((res) => updateViewItems(res));
      }
    });
  };

  // This will trigger when the item prop changes
  useEffect(() => {
    let jsonparse;
    if (item && typeof item.settings === 'string') jsonparse = JSON.parse(item.settings);
    // if the prop exists and it has an url
    if (item && (item.settings.base64 || jsonparse) && item.media && !item.media.includes('http')) {
      // sets the image to be that base64
      // console.log(jsonparse ? jsonparse.base64 : item.settings.base64);

      setImageUrl(jsonparse ? jsonparse.base64 : item.settings.base64);
    }
    // if the item media exists and it includes http it sets the imagePath
    if (item && item.media && item.media.includes('http')) {
      const url = new URL(item.media);
      isPath(item.media);
      setImagePath(url.pathname);
    } else if (item && item.media && !item.media.includes('http')) {
      isPath('http');
      setImagePath(item.media);
    }
  }, [item]);
  // The action is the URL where it would be store
  if (!enabled) {
    return (
      <>
        {imagePath || imageUrl ? (
          <span>
            {/** https://oauth2.mlsdatatools.com or http://localhost:8000 for the imagePath */}
            <img
              src={imagePath && fullPath && fullPath.includes('http') ? `${BASEURL}${imagePath}` : imageUrl}
              alt="media"
              className={imgClassName ? `${imgClassName} ${className}` : `full-width ${className}`}
              id={id}
            />
            {enabled && (
              <Button
                type="dashed"
                danger
                shape="circle-outline"
                onMouseEnter={() => toRemove(true)}
                onMouseLeave={() => toRemove(false)}
                onClick={deleteMedia}
              >
                <DeleteOutlined />
              </Button>
            )}
          </span>
        ) : (
          <>
            <Tooltip title="Avatar" placement="bottom">
              <span style={{ overflow: 'hidden', position: 'relative' }}>
                {mediaType.includes('Media-Member-Photo') && userAvatar && !isEmpty(userAvatar) ? (
                  <img
                    src={userAvatar}
                    alt="avatar"
                    style={{
                      width: 50, height: 50, borderRadius: 5, marginTop: 5,
                    }}
                    className={className}
                    id={id}
                  />
                ) : (
                  mediaType.includes('Media-Member-Photo') && <UserOutlined style={{ color: 'white', paddingTop: 25 }} />
                )}
              </span>
            </Tooltip>
            {mediaType.includes('Logo') && (
              <Tooltip title="Logo">
                <span style={{ overflow: 'hidden', position: 'relative' }}>
                  <MlsLogo id={id} className={className} style={{ width: '100%' }} />
                </span>
              </Tooltip>
            )}
          </>
        )}
      </>
    );
  }
  return (
    <RemoveButtonGroup
      className={className}
      id={id}
      display={(item && item.id) ? enabled : fakeId}
      onRemoveClick={!fakeId ? (() => removeItem(item.id)) : () => removeItem(fakeId)}
    >
      <Upload
        disabled={!enabled ? !enabled : remove}
        name="media"
        listType={enabled && fakeId ? 'picture-card' : null}
        className={enabled && 'upload-view-item-media-enable'}
        showUploadList={false}
        customRequest={!item ? customPostRequest : customPatchRequest}
        // action={!item ? `${CMS}/viewsItems/` : `${CMS}/viewsItems/${item?.id}/`}
        // method={!item ? 'post' : 'patch'}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {imagePath || imageUrl ? (
          <span>
            {/** https://oauth2.mlsdatatools.com or http://localhost:8000 for the imagePath */}
            <img
              src={imagePath && fullPath && fullPath.includes('http') ? `${BASEURL}${imagePath}` : imageUrl}
              alt="media"
              style={{ width: '100%' }}
            />
            {enabled && (
              <Button
                type="dashed"
                danger
                shape="circle-outline"
                onMouseEnter={() => toRemove(true)}
                onMouseLeave={() => toRemove(false)}
                onClick={deleteMedia}
              >
                <DeleteOutlined />
              </Button>
            )}
          </span>
        ) : (
          <>
            <Tooltip title="Avatar" placement="bottom">
              <span style={{ overflow: 'hidden', position: 'relative' }}>
                {mediaType.includes('Media-Member-Photo') && userAvatar && !isEmpty(userAvatar) ? (
                  <img
                    src={userAvatar}
                    alt="avatar"
                    style={{
                      width: 50, height: 50, borderRadius: 5, marginTop: 5,
                    }}
                  />
                ) : (
                  mediaType.includes('Media-Member-Photo') && <UserOutlined style={{ color: 'white', paddingTop: 25 }} />
                )}
              </span>
            </Tooltip>
            {mediaType.includes('Logo') && !fakeId && (
              <span className="media-logo-inview-item-media" style={{ overflow: 'hidden', position: 'relative' }}>
                <MlsLogo style={{ width: '100%' }} />
              </span>
            )}
            {mediaType.includes('Logo') && fakeId && (
              <span className="media-logo-inview-item-media" style={{ overflow: 'hidden', position: 'relative' }}>
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              </span>
            )}
          </>
        )}
      </Upload>
    </RemoveButtonGroup>
  );
};

export default connect(['selectedView', 'userAvatar', 'user'], actions)(ViewItemMedia);
